import React, { useState } from "react";
//boostrap
import { Form } from "react-bootstrap";
//componentes
import ModuleEditForm from "./ModulesEditForm";
//redux
import { useSelector, useDispatch } from "react-redux";
//tools
import { changeTitle } from "../../../tools/admFunctions";
import { SET_HOURS_ERROR } from "../../../store/actions/validationDataAction";

function FormEdit({ certificate_data, setShow, id }) {
  const error_state = useSelector((states) => states.error_state);
  const dispatch = useDispatch();
  const [title, setTitle] = useState(certificate_data.title);
  const [hours, setHours] = useState(certificate_data.hours);

  return (
    <Form method="GET" onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="border-bottom">Título</Form.Label>
        <Form.Control
          onInput={(e) => changeTitle(e, dispatch, error_state, setTitle)}
          type="text"
          placeholder="Nome do certificado"
          isInvalid={error_state.titleErr}
          value={title}
        />
      </Form.Group>

      <Form.Group controlId="hours">
        <Form.Label className="border-bottom">Carga horária</Form.Label>
        <Form.Control
          onInput={(e) => {
            if (error_state.hoursErr) dispatch(SET_HOURS_ERROR(false));

            setHours(() => e.target.value);
          }}
          type="number"
          placeholder="Carga horária total do certificado"
          isInvalid={error_state.hoursErr}
          value={hours}
        />
      </Form.Group>
      <ModuleEditForm
        title={title}
        hours={hours}
        certificate_data={certificate_data}
        setShow={setShow}
        id={id}
      />
    </Form>
  );
}

export default FormEdit;
