import styles from "./BoxCertificateImage.module.css";
const BoxCertificateImage = ({ children, url }) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className={styles.background_white}></div>
      <div className={styles.boxImage}>
        {children}
        <a download href={url}>
          <button className={styles.buttonDownload}>Download</button>
        </a>
      </div>
    </div>
  );
};

export default BoxCertificateImage;
