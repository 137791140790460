import React, { useState, useRef } from "react";
import { Popover, Button, Overlay } from "react-bootstrap";
import { FaEraser } from "react-icons/fa";

function PopoverConfirmation({ delete_click }) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const confirmationClick = () => {
    delete_click();

    setShow(!show);
  };

  return (
    <div ref={ref}>
      <Button className="bg-danger" onClick={handleClick}>
        <FaEraser />
      </Button>

      <Overlay
        show={show}
        target={target}
        placement="top"
        container={ref}
        containerPadding={20}
        onHide={() => setShow(!show)}
        rootClose
      >
        <Popover id="popover-contained">
          <Popover.Header as="h3">Você tem certeza ?</Popover.Header>
          <Popover.Body>
            <Button
              onClick={confirmationClick}
              className="me-1"
              variant="danger"
            >
              Deletar
            </Button>

            <Button onClick={() => setShow(!show)} variant="light">
              Cancelar
            </Button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default PopoverConfirmation;
