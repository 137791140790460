import { user_with_expired_token } from "./utils";

export async function getModules() {
  try {
    const res = await fetch("/api/modules");
    const dataModule = await res.json();
    return dataModule;
  } catch (err) {
    console.log(err);
  }
}

export async function getPages(id) {
  try {
    const res = await fetch("/api/pages", {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ id }),
    });
    let dataPage = await res.json();
    return dataPage.map((page) => {
      page.isSelect = false;
      return page;
    });
  } catch (err) {
    console.log(err);
  }
}

export async function deleteCertified(id, dispatch, navigate) {
  try {
    const http = await fetch(`/api/delete_certified/${id}`, {
      method: "DELETE",
    });
    const res = await http.json();
    if (res === "access refused") user_with_expired_token(dispatch, navigate);
  } catch (err) {
    console.log(err);
  }
}

export function savecertified(title, pages, hours) {
  return new Promise(async (resolve, reject) => {
    await fetch("/api/register_certified", {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ title, pages, hours }),
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
}

export function editCertified(title, pages, id, hours, dispatch, navigate) {
  return new Promise(async (resolve, reject) => {
    await fetch(`/api/edit_certified/${id}`, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ title, pages, hours }),
    })
      .then((res) => res.json())
      .then((text) => {
        if (text === "access refused")
          user_with_expired_token(dispatch, navigate);

        resolve(text);
      })
      .catch((err) => reject(err));
  });
}

export async function getStudents() {
  try {
    const res = await fetch("/api/all_students");
    const dataAllStudents = await res.json();
    return dataAllStudents;
  } catch (err) {
    console.log(err);
  }
}

export async function getStudentProgress(id) {
  try {
    const res = await fetch("/api/student_progress", {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ id }),
    });
    let student_progress = await res.json();
    return student_progress;
  } catch (err) {
    console.log(err);
  }
}

export async function gettingAllCertificateModels() {
  try {
    const res = await fetch("/api/all_certified");
    const allCertificateModels = await res.json();

    return allCertificateModels;
  } catch (err) {
    console.log(err);
  }
}
