import my_dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "dayjs/locale";
// import "dayjs/locale/pt-br";

my_dayjs.extend(utc);
my_dayjs.extend(timezone);

// Defina o fuso horário padrão para o Brasil (por exemplo, America/Sao_Paulo)
my_dayjs.tz.setDefault("America/Sao_Paulo");

export { my_dayjs };
