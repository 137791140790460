import styled from "@emotion/styled";

export const MyContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "400px",
  width: "100%",
  //   boxShadow: 24,
});

export const MyContent = styled("div")({
  background: "white",
  borderRadius: "calc(.3rem - 1px)",

  "& .my-header": {
    display: "flex",
    padding: "16px",
    borderBottom: "1px solid #dee2e6",
  },
  "& .my-body": {
    display: "flex",
    padding: "16px",
    borderBottom: "1px solid #dee2e6",
  },
  "& .my-fotter": {
    display: "flex",
    padding: "12px",
    justifyContent: "end",
    gap: "8px",

    "@media only screen and (max-width: 400px)": {
      flexDirection: "column-reverse !important",
      // background: "blue",
    },
  },
});
