import { Accordion, Card, Form, FormCheck } from "react-bootstrap";
import styles from "../BoxResult/boxResult.module.css";
const ProgressStudent = ({ resultSearch }) => {
  return (
    <Form.Group controlId="Progress" className="my-2">
      <Card.Title as={"h5"} className="mt-3">
        Progresso detalhado
      </Card.Title>
      {/* <Form.Label className={styles.legendForm}>PROGRESSO</Form.Label> */}
      <Accordion flush className="card">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{`${resultSearch.complete_progress} de ${resultSearch.progress.length} aulas completas`}</Accordion.Header>
          <Accordion.Body>
            {resultSearch.progress.map((page) => {
              return (
                <Card key={page.page_id} style={{ opacity: "0.5" }}>
                  <Card.Body className="d-flex align-items-center">
                    <FormCheck
                      className="pe-3"
                      readOnly
                      checked={page.is_completed}
                      disabled
                    />
                    <label>{page.page_name}</label>
                  </Card.Body>
                </Card>
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Form.Group>
  );
};

export default ProgressStudent;
