import Backdrop from "@mui/material/Backdrop";
import PropagateLoader from "react-spinners/PropagateLoader";
import styles from "./GenerateCertificado.module.css";
import { useSelector } from "react-redux";

const BackgroundCertificate = () => {
  const all_states = useSelector((state) => state);

  return all_states.loading_states.generatingCertificates ? (
    // || all_states.loading_states.loading_rename_certificates
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      className={styles.BackdropStyle}
    >
      {/* {all_states.loading_states.loading_rename_certificates ? (
         <>
           <h3 className={styles.titleInfo}>
             AGUARDE ENQUANTO <br />
             ATUALIZAMOS SEUS DADOS :)
           </h3>
           <PropagateLoader size={40} color={"#ffffff"} />
         </>
       ) : ( */}
        <PropagateLoader size={40} color={"#ffffff"} />
      {/* )} */}
    </Backdrop>
  ) : (
    <></>
  );
};

export default BackgroundCertificate;
