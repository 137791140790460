import { useEffect, useState } from "react";
import { Button, Card, FormCheck, Spinner } from "react-bootstrap";

const PendingClasses = ({
  pages,
  progress,
  showModelToForceGenerateCertificate,
}) => {
  const [classes, setClasses] = useState(false);

  useEffect(() => {
    let pages_flat = Object.values(pages).flatMap((module) => {
      return module;
    });
    pages_flat = pages_flat.filter((page) => {
      return progress.some(
        (page_progress) =>
          page_progress.page_id === page.page_id && !page_progress.is_completed
      );
    });
    setClasses(() => pages_flat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, progress]);

  return (
    <div
      style={{
        display: "grid",
        gap: "16px",
        gridTemplateRows: "auto auto",
      }}
    >
      {!classes ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : !classes.length ? (
        <p className="m-auto">
          Certificado pode ser gerado, não há nenhuma pendência para geração
          dele.
        </p>
      ) : (
        classes.map((page) => {
          return (
            <Card key={page.page_id} style={{ opacity: "0.5" }}>
              <Card.Body className="d-flex align-items-center">
                <FormCheck className="pe-3" readOnly checked={false} disabled />
                <label>{page.name}</label>
              </Card.Body>
            </Card>
          );
        })
      )}
      <Button onClick={showModelToForceGenerateCertificate}>
        Gerar certificado
      </Button>
    </div>
  );
};

export default PendingClasses;
