import React, { useState } from "react";
import AdmLayout from "../layouts/AdmLayout";

// redux
import { AiOutlineBorderlessTable } from "react-icons/ai";
import ModalResult from "../components/AdmComponents/ModalResult";
import FormSearch from "../components/AdmComponents/ConsultStudent/Form/";
import { useSearchContext } from "../contexts/StudentSearchContext";

const ConsultStudentPage = () => {
  const [show, setShow] = useState(false);
  const { resultSearch } = useSearchContext();

  return (
    <AdmLayout>
      <div className="mb-5 mt-4">
        <h1 className="d-flex align-items-center m-0">
          <AiOutlineBorderlessTable />
          Consultar dados
        </h1>
        <FormSearch setShow={setShow} />
        {resultSearch ? (
          <ModalResult
            show={show}
            setShow={setShow}
            resultSearch={resultSearch}
          />
        ) : null}
      </div>
    </AdmLayout>
  );
};

export default ConsultStudentPage;
