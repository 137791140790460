import React from "react";
// bootstrap
import { FormCheck, Card } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
// actions
import { SET_STATE_PAGE } from "../../../store/actions/pagesActions";
import { SET_STATE } from "../../../store/actions/allSelectActions";
import { SET_MODULES_ERROR } from "../../../store/actions/validationDataAction";

function PagesForm({ module_id }) {
  const all_pages = useSelector((states) => states.pages);
  const allSelect = useSelector((states) => states.allSelect);
  const error_state = useSelector((states) => states.error_state);
  const dispatch = useDispatch();

  function changeClick(e) {
    // disparo estilo de falta de informação no form
    if (error_state.modulesErr) dispatch(SET_MODULES_ERROR(false));

    // retiro a seleção do allSelect caso esteja selecionado
    if (allSelect[`${module_id}`]) {
      dispatch(SET_STATE({ key: module_id, value: false }));
    }

    // altero o estado de uma seleção.
    const new_state = all_pages[`${module_id}`].map((page) => {
      if (page.page_id === e.target.id) {
        page.isSelect = !page.isSelect;
      }
      return page;
    });
    dispatch(SET_STATE_PAGE({ id: module_id, pages: new_state }));

    // aqui eu altero o valor do botão allSelect
    // caso todas as pages forem selecionadas
    let isAll_select = all_pages[`${module_id}`].filter(
      (page) => page.isSelect === false
    );
    if (!isAll_select.length) {
      dispatch(SET_STATE({ key: module_id, value: true }));
    }
  }

  return (
    <>
      <div>
        {all_pages[`${module_id}`].map((page, index) => {
          return (
            <Card key={index}>
              <Card.Body className="d-flex align-items-center">
                <FormCheck
                  aria-label="Checkbox for following text input"
                  id={page.page_id}
                  className="px-3"
                  checked={page.isSelect}
                  onChange={changeClick}
                />
                <label htmlFor={page.page_id}>{page.name}</label>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </>
  );
}
export default PagesForm;
