const userFormErr = {
  nothingDataErr: false,
  isNotEmailErr: false,
  nothingPasswordDataErr: false,
  isNotPasswordValid: false,
  isNotSamePassword: false,
};
const resetUserPasswordErr = {
  currentPasswordNothingData: false,
  newPasswordNothingData: false,
  repeatPasswordNothingData: false,
  isNotPasswordValid: false,
  isNotSamePassword: false,
  isSameCurrentPassword: false,
};
const forgotUserPasswordErr = {
  nothingDataErr: false,
  isNotEmailErr: false,
  newPasswordNothingData: false,
  repeatPasswordNothingData: false,
  isNotPasswordValid: false,
  isNotSamePassword: false,
};

const studentSeach = {
  nothingDataErr: false,
  isNotEmailErr: false,
};

// arrumar melhor os estados
const initialState = {
  titleErr: false,
  modulesErr: false,
  hoursErr: false,
  changeErr: false,
  isNotEmailErr: false,
  nothingDataErr: false,
  nothingPasswordDataErr: false,
  loginFailedErr: false,
  userFormErr,
  resetUserPasswordErr,
  forgotUserPasswordErr,
  studentSeach,
};

export default function validationDataReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_TITLE_ERROR":
      return Object.assign({}, state, { titleErr: action.payload });

    case "SET_MODULES_ERROR":
      return Object.assign({}, state, { modulesErr: action.payload });

    case "SET_HOURS_ERROR":
      return Object.assign({}, state, { hoursErr: action.payload });

    case "SET_CHANGE_ERROR":
      return Object.assign({}, state, { changeErr: action.payload });

    case "SET_ISNOTEMAIL_ERROR":
      return Object.assign({}, state, { isNotEmailErr: action.payload });

    case "SET_NOTHINGDATA_ERROR":
      return Object.assign({}, state, { nothingDataErr: action.payload });

    case "SET_NOTHING_PASSWORD_DATA_ERROR":
      return Object.assign({}, state, {
        nothingPasswordDataErr: action.payload,
      });
    case "SET_LOGIN_FAILED_ERROR":
      return Object.assign({}, state, { loginFailedErr: action.payload });

    case "SET_USER_FORM_ERROR":
      return Object.assign({}, state, {
        userFormErr: Object.assign(state.userFormErr, action.payload),
      });
    case "SET_USER_RESET_PASSWORD_ERROR":
      return Object.assign({}, state, {
        resetUserPasswordErr: Object.assign(
          state.resetUserPasswordErr,
          action.payload
        ),
      });
    case "SET_FORGOT_PASSWORD_ERROR":
      return Object.assign({}, state, {
        forgotUserPasswordErr: Object.assign(
          state.forgotUserPasswordErr,
          action.payload
        ),
      });
    case "SET_SEACH_USER_ERROR":
      return Object.assign({}, state, {
        studentSeach: Object.assign(
          state.studentSeach,
          action.payload
        ),
      });

    default:
      return state;
  }
}
