import { Button, Modal } from "react-bootstrap";
import BoxResult from "../ConsultStudent/BoxResult";
// import { Modal } from "@mui/material";
// import { MyContainer } from "./styles";

const ModalResult = ({ show, setShow, resultSearch }) => {
  // return (
  //   <Modal
  //     open={show}
  //     // onHide={() => setShow(false)}
  //     // backdrop="static"
  //     // keyboard={false}
  //     disableAutoFocus={true}
  //   >
  //     <MyContainer>
  //       <div className="content">
  //         <BoxResult resultSearch={resultSearch} />
  //       </div>
  //       <div className="footter">
  //         <Button
  //           variant="secondary"
  //           onClick={() => setShow(false)}
  //           className="w-100"
  //         >
  //           Fechar
  //         </Button>
  //       </div>
  //     </MyContainer>
  //   </Modal>
  // );
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <BoxResult resultSearch={resultSearch} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShow(false)}
          className="w-100"
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalResult;
