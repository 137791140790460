import { Button } from "@mui/material";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_SEACH_USER_ERROR } from "../../../../store/actions/validationDataAction";
import { seachClick } from "../../../../tools/consultStudentFunctions";
import styles from "./form.module.css";
import { useSearchContext } from "../../../../contexts/StudentSearchContext";

const FormResult = ({ setShow }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNotStudent, setIsNotStudent] = useState(false);
  const { changeCurrentStudentEmail, changeCurrentResultSearch } =
    useSearchContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_errs = useSelector((states) => states.error_state).studentSeach;

  return (
    <Form onSubmit={(e) => e.preventDefault()} className={styles.formSeach}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className={styles.legendForm}>EMAIL</Form.Label>
        <Form.Control
          className={styles.inputSeach}
          type="email"
          placeholder="Digite o email do aluno"
          onChange={(e) => {
            if (all_errs.nothingDataErr || all_errs.isNotEmailErr) {
              dispatch(
                SET_SEACH_USER_ERROR({
                  nothingDataErr: false,
                  isNotEmailErr: false,
                })
              );
            }
            if (isNotStudent) {
              setIsNotStudent(false);
            }
            setEmail(() => e.target.value);
          }}
          value={email}
          isInvalid={
            all_errs.nothingDataErr || all_errs.isNotEmailErr || isNotStudent
          }
        />
        <Form.Control.Feedback type="invalid" className="text-start">
          {all_errs.nothingDataErr
            ? "O campo não deve ficar vazio."
            : all_errs.isNotEmailErr
            ? "O valor digitado não é um e-mail."
            : isNotStudent
            ? "Email não está cadastrado no curso."
            : ""}
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        style={{ background: "#212529" }}
        className={styles.buttonSeach}
        onClick={() => {
          seachClick(
            email,
            dispatch,
            all_errs,
            setLoading,
            changeCurrentResultSearch,
            setShow,
            setIsNotStudent,
            navigate
          );

          changeCurrentStudentEmail(email);
        }}
        disabled={loading}
      >
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "VERIFICAR"
        )}
      </Button>
    </Form>
  );
};

export default FormResult;
