import React, { useEffect } from "react";
// bootstrap
import { FormCheck, Card } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
// tools
import {
  changeClickEditForm,
  initial_change_edit_form,
} from "../../../tools/admFunctions";
import { BiBook } from "react-icons/bi";

function PagesEditForm({ module_id, certificate_data }) {
  const all_pages = useSelector((states) => states.pages);
  const allSelect = useSelector((states) => states.allSelect);
  const error_state = useSelector((states) => states.error_state);

  const dispatch = useDispatch();
  const handleClick = (e) => {
    changeClickEditForm(
      e,
      error_state,
      allSelect,
      dispatch,
      module_id,
      all_pages
    );
  };

  useEffect(() => {
    if (certificate_data) {
      initial_change_edit_form(
        all_pages,
        dispatch,
        module_id,
        certificate_data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {all_pages[`${module_id}`].map((page, index) => {
        return (
          <Card key={index}>
            <Card.Body className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FormCheck
                  aria-label="Checkbox for following text input"
                  id={page.page_id}
                  className="px-3"
                  checked={page.isSelect}
                  onChange={handleClick}
                />
                <label htmlFor={page.page_id}>{page.name}</label>
              </div>
              {page.is_published ? <BiBook /> : <></>}
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}
export default PagesEditForm;
