import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AnyCertified from "../HomeSeach/AnyCertified/";
import styles from "../HomeSeach/FormHome/formHome.module.css";
import { SET_NOTHINGDATA_ERROR } from "../../store/actions/validationDataAction";
import { useNavigate } from "react-router-dom";
import { validateCertificate } from "../../tools/AuthenticityForm";

const AuthenticityForm = () => {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateNotValidadte, setCertificateNotValidadte] = useState(false);
  const navigate = useNavigate();

  const all_states = useSelector((states) => states);
  const dispatch = useDispatch();

  return (
    <Form className={styles.formHome} onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="mb-3" controlId="formBasicText">
        <Form.Label className={styles.labelHome}>
          DIGITE O IDENTIFICADOR DO CERTIFICADO
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Nº do certificado"
          className={styles.inputHome}
          onChange={(e) => {
            if (all_states.error_state.nothingDataErr)
              dispatch(SET_NOTHINGDATA_ERROR());
            if (certificateNotValidadte) setCertificateNotValidadte(false);
            setCertificateNumber(e.target.value.toLowerCase());
          }}
          value={certificateNumber}
          isInvalid={all_states.error_state.nothingDataErr}
        />
        <Form.Control.Feedback type="invalid" className="text-start">
          {all_states.error_state.nothingDataErr
            ? "O campo não pode ficar vazio"
            : ""}
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        className={styles.buttonHome}
        onClick={() =>
          validateCertificate(
            dispatch,
            certificateNumber,
            setCertificateNotValidadte,
            navigate
          )
        }
        disabled={all_states.loading_states.loading_authenticity_certificate}
      >
        {all_states.loading_states.loading_authenticity_certificate
          ? "Consultando..."
          : "Consultar certificados"}
      </Button>

      {certificateNotValidadte ? (
        <AnyCertified text="Certificado não encontrado" />
      ) : (
        <></>
      )}
    </Form>
  );
};

export default AuthenticityForm;
