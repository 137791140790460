import { useSelector } from "react-redux";
import styles from "./result.module.css";

const ResultSeach = ({ certificatesGenerated }) => {
  const all_states = useSelector((states) => states);

  return (
    <>
      {certificatesGenerated.length &&
      !all_states.loading_states.seachStudent ? (
        <>
          <div className={styles.titleResultCerified}>
            CERTIFICADOS DISPONÍVEIS
          </div>
          {certificatesGenerated.map((certified, index) => {
            return (
              <a
                href={`/certificado/${certified.certificateId}`}
                target="_blank"
                rel="noreferrer"
                key={certified.certificateId}
              >
                <button type="button" className={styles.buttonCertified}>
                  {certified.moduleName}
                </button>
              </a>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ResultSeach;
