import styles from "../../HomeSeach/FormHome/formHome.module.css";
// import formLoginStyle from "./form.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import {
  changeEmailInput,
  changePasswordInput,
  getTokenLogin,
  isLoggedIn,
} from "../../../tools/loginFunctions";
import { verify_and_update_states } from "../../../tools/verificateDataFunctions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const FormLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const all_states = useSelector((states) => states);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    verify_and_update_states(all_states, dispatch);
    if (JSON.parse(localStorage.getItem("Logged"))) {
      isLoggedIn(dispatch, navigate);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      className={styles.formHome}
      onSubmit={(e) => {
        e.preventDefault();
        getTokenLogin(
          email,
          password,
          dispatch,
          navigate,
          enqueueSnackbar,
          setPassword
        );
      }}
    >
      <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
        <Form.Label className={styles.labelHome}>DIGITE SEU EMAIL</Form.Label>
        <Form.Control
          type="email"
          placeholder="EMAIL"
          className={styles.inputHome}
          onChange={(e) => changeEmailInput(e, setEmail, all_states, dispatch)}
          value={email}
          isInvalid={
            all_states.error_state.nothingDataErr ||
            all_states.error_state.isNotEmailErr
          }
        />
        <Form.Control.Feedback type="invalid" className="text-start">
          {all_states.error_state.nothingDataErr
            ? "Digite um e-mail no campo acima."
            : all_states.error_state.isNotEmailErr
            ? "O valor digitado não é um e-mail :("
            : ""}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 text-start" controlId="formBasicPassword">
        <Form.Label className={styles.labelHome}>DIGITE SUA SENHA</Form.Label>
        <Form.Control
          type="password"
          placeholder="SENHA"
          className={styles.inputHome}
          onChange={(e) =>
            changePasswordInput(e, setPassword, all_states, dispatch)
          }
          value={password}
          isInvalid={all_states.error_state.nothingPasswordDataErr}
        />
        <Form.Control.Feedback type="invalid" className="text-start">
          {all_states.error_state.nothingPasswordDataErr
            ? "Digite sua senha no campo acima."
            : ""}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className={styles.buttonHome}
        type="submit"
        disabled={all_states.loading_states.validateDataLogin}
      >
        {all_states.loading_states.validateDataLogin
          ? "Validando dados..."
          : "Entrar"}
      </Button>
      {/* <p className={`${formLoginStyle.paragraphForm} text-center`}>
        <Link
          to="/forgot_password"
          className={`${formLoginStyle.forgot_password}`}
        >
          Esqueci da minha senha
        </Link>
      </p>
      <p className={formLoginStyle.paragraphForm}>
        Não tem conta ainda? <Link to="/register">Registre-se</Link>
      </p> */}
    </Form>
  );
};

export default FormLogin;
