import styled from "@emotion/styled";

export const MyContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "0.5rem",

  ".paragraph-label": {
    marginBottom: "0.5rem",
  },

  ".box-input": {
    display: "flex",
    gap: "0.25rem",

    input: {
      color: "#90847E",
      fontSize: "1rem",
      fontWeight: "$medium",
      lineHeight: "1.5rem",
      borderRadius: "8px",
      minHeight: "3rem",
      fontFamily: "var(--font-inter)",
      // maxWidth: "25rem",
      // width: "100vw",
      width: "100%",

      border: "1px solid #C4C4C4",
      background: "#FFF",

      paddingLeft: "1.25rem",
      paddingRight: "1.25rem",
    },

    "input:disabled": {
      opacity: 0.6,
    },

    ".buttons-input": {
      display: "flex",
      gap: "0.25rem",
    },

    button: {
      height: "48px",
      width: "48px",
      borderRadius: "8px !important",
      boxShadow: "initial !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px",
    },
  },
});
