import Swal from "sweetalert2";
import { SET_STATE } from "../store/actions/allSelectActions";
import { SET_STATE_INITIAL } from "../store/actions/initialStatePagesActions";
import {
  GENERATING_CERTIFICATES,
  SET_CERTIFICATE,
  SET_FORM_DATA,
  SET_SAVE_BUTTON,
} from "../store/actions/loadingActions";

import { RECOVER_STATE, SET_STATE_PAGE } from "../store/actions/pagesActions";
import {
  SET_CHANGE_ERROR,
  SET_HOURS_ERROR,
  SET_MODULES_ERROR,
  SET_TITLE_ERROR,
} from "../store/actions/validationDataAction";

import {
  getting_all_modules,
  getting_all_pages,
} from "../store/folder-thunk/hotmart_thunk";
import { ValidadeEditData } from "./formFunctions";
import { editCertified } from "./hotmartAPI";
import { user_with_expired_token } from "./utils";

const removeLoadings = (dispatch, states) => {
  if (
    Object.keys(states.pages).length === states.modules.length &&
    Object.keys(states.pages).length !== 0 &&
    states.loading_states.loading_data_form &&
    Object.keys(states.allSelect).length !== 0
  ) {
    dispatch(
      SET_STATE_INITIAL({
        pages: JSON.stringify(states.pages),
        allSelect: JSON.stringify(states.allSelect),
      })
    );
    dispatch(SET_FORM_DATA(false));
  }
};

const createPageState = (dispatch, states) => {
  if (
    !Object.keys(states.pages).length &&
    states.modules.length &&
    states.modules.length !== 0
  ) {
    states.modules.forEach((module) => {
      // Crio os estados de seleção de páginas
      dispatch(SET_STATE({ key: module.module_id, value: false }));
      dispatch(getting_all_pages(module.module_id));
    });
  }
};

const createModulesState = (dispatch, states) => {
  if (!states.modules.length) {
    dispatch(getting_all_modules());
  }
};

const changeTitle = (e, dispatch, error_state, setTitle) => {
  if (error_state.titleErr) dispatch(SET_TITLE_ERROR(false));
  if (error_state.changeErr) dispatch(SET_CHANGE_ERROR(false));

  let current_value = e.target.value;
  setTitle(() =>
    current_value.length > 1
      ? current_value[0].toUpperCase() + current_value.slice(1)
      : current_value
  );
};

const recoverStateEditForm = (show, initialState, dispatch) => {
  if (!show && Object.keys(initialState).length) {
    dispatch(
      RECOVER_STATE({
        pages: JSON.parse(initialState.pages),
        allSelect: JSON.parse(initialState.allSelect),
      })
    );

    dispatch(SET_TITLE_ERROR(false));
    dispatch(SET_HOURS_ERROR(false));
    dispatch(SET_MODULES_ERROR(false));
    dispatch(SET_CHANGE_ERROR(false));
  }
};

const initial_change_edit_form = (
  all_pages,
  dispatch,
  module_id,
  certificate_data
) => {
  // Seleciona os pages selecionados com base no DB
  const new_state = all_pages[`${module_id}`].map((page) => {
    certificate_data.forEach((e) => {
      if (e.page_id === page.page_id) {
        page.isSelect = true;
      }
    });
    return page;
  });
  dispatch(SET_STATE_PAGE({ id: module_id, pages: new_state }));

  let isAll_select = all_pages[`${module_id}`].filter(
    (page) => page.isSelect === false
  );
  if (!isAll_select.length) {
    dispatch(SET_STATE({ key: module_id, value: true }));
  }
};

const changeClickEditForm = (
  e,
  error_state,
  allSelect,
  dispatch,
  module_id,
  all_pages
) => {
  // disparo estilo de falta de informação no form
  if (error_state.modulesErr) dispatch(SET_MODULES_ERROR(false));
  if (error_state.changeErr) dispatch(SET_CHANGE_ERROR(false));

  // retiro a seleção do allSelect caso esteja selecionado
  if (allSelect[`${module_id}`]) {
    dispatch(SET_STATE({ key: module_id, value: false }));
  }

  // altero o estado de uma seleção.
  const new_state = all_pages[`${module_id}`].map((page) => {
    if (page.page_id === e.target.id) {
      page.isSelect = !page.isSelect;
    }
    return page;
  });
  dispatch(SET_STATE_PAGE({ id: module_id, pages: new_state }));

  // aqui altero o valor do botão allSelect
  // caso todas as pages forem selecionadas
  let isAll_select = all_pages[`${module_id}`].filter(
    (page) => page.isSelect === false
  );
  if (!isAll_select.length) {
    dispatch(SET_STATE({ key: module_id, value: true }));
  }
};

const updateCertified = (
  selected_pages,
  dispatch,
  title,
  setShow,
  pages,
  certificate_data,
  setEditLoading,
  id,
  setShowForm,
  hours,
  navigate
) => {
  // pequena "validação" se houve ou não os dados esperados preenchidos.
  const pages_to_save = selected_pages(pages);
  setShow(false);
  if (!title || !Object.keys(pages_to_save).length || !hours) {
    if (!title) {
      dispatch(SET_TITLE_ERROR(true));
    }
    if (!Object.keys(pages_to_save).length) {
      dispatch(SET_MODULES_ERROR(true));
    }
    if (!hours) {
      dispatch(SET_HOURS_ERROR(true));
    }
  } else if (ValidadeEditData(certificate_data, pages_to_save, title, hours)) {
    dispatch(SET_CHANGE_ERROR(true));
  } else {
    dispatch(SET_SAVE_BUTTON(true));
    dispatch(SET_CERTIFICATE(true));
    setShow(false);
    setEditLoading(true);
    edit_click_save(
      pages_to_save,
      title,
      id,
      dispatch,
      setEditLoading,
      setShowForm,
      hours,
      navigate
    );
  }
};

const edit_click_save = (
  selected_pages,
  title,
  id,
  dispatch,
  setEditLoading,
  setShowForm,
  hours,
  navigate
) => {
  // aqui quando o certificado for editado ocorrerá uma nova requisição
  // para pegar os novos dados do banco.
  editCertified(title, selected_pages, id, hours, dispatch, navigate).then(
    () => {
      dispatch(SET_SAVE_BUTTON(false));
      dispatch(SET_CERTIFICATE(false));
      setEditLoading(false);
      setShowForm(false);
    }
  );
};

// ativa rota para gerar todos os certificados.
const generateCertificates = (dispatch, navigate) => {
  Swal.fire({
    title: "Você quer gerar todos os certificados agora?",
    html: "<div style=''><b>⚠️Atenção: </b>Pode levar alguns instantes, então é importante que aguarde o processo ser concluido.</div>",
    showCancelButton: true,
    confirmButtonText: "Quero gerar",
    cancelButtonText: `Agora não`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      dispatch(GENERATING_CERTIFICATES(true));
      fetch("/api/register_and_generate_all_certicates", { method: "POST" })
        .then((http) => http.json())
        .then((res) => {
          if (res === "access refused") {
            dispatch(GENERATING_CERTIFICATES(false));
            return user_with_expired_token(dispatch, navigate);
          }
          if (res === "success generate") {
            dispatch(GENERATING_CERTIFICATES(false));
            Swal.fire({
              title: `Processo concluído :)`,
              icon: "success",
              confirmButtonText: "Entendi",
            });
          } else {
            dispatch(GENERATING_CERTIFICATES(false));
            Swal.fire({
              icon: "error",
              title: "Ocorreu algum erro :(",
              confirmButtonText: "Entendi",
            });
          }
        });
    }
  });
};

export {
  removeLoadings,
  createPageState,
  createModulesState,
  changeTitle,
  recoverStateEditForm,
  initial_change_edit_form,
  changeClickEditForm,
  updateCertified,
  generateCertificates,
};
