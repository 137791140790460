import { verify_and_update_states } from "./verificateDataFunctions";
import {
  SET_NOTHING_PASSWORD_DATA_ERROR,
  SET_NOTHINGDATA_ERROR,
  SET_ISNOTEMAIL_ERROR,
} from "../store/actions/validationDataAction";
import { GETING_DATA_USER_LOGGED } from "../store/actions/userLoggedActions";
import { SET_VALIDATE_DATA_LOGIN } from "../store/actions/loadingActions";
import { IsEmail } from "./utils";

const changeEmailInput = (e, setEmail, all_states, dispatch) => {
  verify_and_update_states(all_states, dispatch, false, () => {});
  setEmail(() => e.target.value);
};

const changePasswordInput = (e, setPassword, all_states, dispatch) => {
  verify_and_update_states(all_states, dispatch);

  setPassword(() => e.target.value);
};

const getTokenLogin = async (
  email,
  password,
  dispatch,
  navigate,
  enqueueSnackbar,
  setPassword
) => {
  if (email) {
    if (!IsEmail(email)) {
      dispatch(SET_ISNOTEMAIL_ERROR(true));
      return;
    }
  } else {
    dispatch(SET_NOTHINGDATA_ERROR(true));
    if (!password) {
      dispatch(SET_NOTHING_PASSWORD_DATA_ERROR(true));
    }
    return;
  }
  if (!password) {
    dispatch(SET_NOTHING_PASSWORD_DATA_ERROR(true));
    return;
  }

  try {
    dispatch(SET_VALIDATE_DATA_LOGIN(true));
    const res = await fetch("/api/login", {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ email, password }),
    });
    let data = await res.json();

    if (res.status !== 200) {
      setPassword("");
      if (enqueueSnackbar) {
        enqueueSnackbar(
          res.status === 400
            ? "Usuário bloqueado :("
            : res.status === 401
            ? "Email ou senha incorreto"
            : "Ocorreu algum erro",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
      dispatch(SET_VALIDATE_DATA_LOGIN());
      return data;
    } else {
      localStorage.setItem("Logged", JSON.stringify(true));
      dispatch(SET_VALIDATE_DATA_LOGIN());
      isLoggedIn(dispatch, navigate);
    }
  } catch (err) {
    console.log("chegiy", err);
  }
};

const isLoggedIn = (dispatch, navigate) => {
  verify_token().then((res) => {
    if (res !== "access refused") {
      dispatch(GETING_DATA_USER_LOGGED(res));
      if (res.isAdmin && navigate) return navigate("/adm");
      if (navigate) navigate("/");
    }
  });
};

const verify_token = async () => {
  const res = await fetch("/api/validation_token");
  return await res.json();
};

export {
  changeEmailInput,
  changePasswordInput,
  getTokenLogin,
  verify_token,
  isLoggedIn,
};
