import React, { useEffect, useState } from "react";
// components
import FormEdit from "./FormEdit";
// bootstrap
import { Button, Offcanvas } from "react-bootstrap";
// Icons
import { RiEditLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
// redux
import { useSelector, useDispatch } from "react-redux";
// tools
import { recoverStateEditForm } from "../../../tools/admFunctions";

function EditCertified({ certificate_data, id }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.initial_states);

  useEffect(() => {
    // altero o estado das pages para seu valor inicial
    // quando formulário for fechado.
    recoverStateEditForm(show, initialState, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <Button className="me-2" onClick={() => setShow(true)}>
        <BiEdit />
      </Button>

      <Offcanvas placement="start" show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <RiEditLine /> Editar
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FormEdit
            setShow={setShow}
            certificate_data={certificate_data}
            id={id}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default EditCertified;
