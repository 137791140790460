// import { Box, Button, Modal, Typography } from "@mui/material";
import { Modal } from "@mui/material";
import { Button, Card, Spinner } from "react-bootstrap";
import { MyContainer, MyContent } from "./styles";
// import { Button, Modal } from "react-bootstrap";

export const CertificateConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  loading,
}) => {
  return (
    <Modal
      open={show}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <MyContainer>
        <MyContent>
          <div className="my-header">
            <Card.Title className="mb-auto" as="h4">
              Você tem certeza ?
            </Card.Title>
          </div>
          <div className="my-body">
            <Card.Text className="mb-auto">
              Confirme sua decisão selecionando um dos botões abaixo:
            </Card.Text>
          </div>
          <div className="my-fotter">
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "115px",
                justifyContent: "center",
              }}
              disabled={loading}
              onClick={handleConfirm}
            >
              {loading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Tenho certeza"
              )}
            </Button>
          </div>
        </MyContent>
      </MyContainer>
    </Modal>
  );
};
