import styled from "@emotion/styled";
import { Switch } from "@mui/material";

export const MyContainer = styled("div")({
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   maxWidth: "500px",
  width: "100%",
  //   boxShadow: 24,
});

export const MyContent = styled("div")({
  background: "white",
  borderRadius: "calc(.3rem - 1px)",

  "& .my-header": {
    display: "flex",
    padding: "16px",
    borderBottom: "1px solid #dee2e6",
  },
  "& .my-body": {
    display: "flex",
    padding: "16px",
    borderBottom: "1px solid #dee2e6",
    flexDirection: "column",
    maxHeight: "calc(71vh - 60px)",
    height: "100vh",
    overflow: "auto",
  },
  "& .my-fotter": {
    display: "flex",
    padding: "12px",
    justifyContent: "end",
    gap: "8px",
    flexDirection: "column-reverse !important",

    "@media only screen and (max-width: 400px)": {
      flexDirection: "column-reverse !important",
      // background: "blue",
    },
  },
});

// export const Android12Switch = styled(Switch)(({ theme }) => ({
//   padding: 8,
//   "& .MuiSwitch-track": {
//     borderRadius: 22 / 2,
//     "&::before, &::after": {
//       content: '""',
//       position: "absolute",
//       top: "50%",
//       transform: "translateY(-50%)",
//       width: 16,
//       height: 16,
//     },
//     "&::before": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
//       left: 12,
//     },
//     "&::after": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
//       right: 12,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "none",
//     width: 16,
//     height: 16,
//     margin: 2,
//   },
// }));
