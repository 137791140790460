import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRouter from "./components/PrivateRouter";
import AdmPage from "./pages/AdmPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
// import RegisterPage from "./pages/RegisterPage";
// import NotFoundPage from "./pages/NotFoundPage";
import CertificatePage from "./pages/CertificatePage";
// import UserPage from "./pages/UserPage";
// import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ConsultStudentPage from "./pages/ConsultStudentPage";
import AuthenticityPage from "./pages/AuthenticityPage";
import { StudentSearchProvider } from "./contexts/StudentSearchContext";
import IntegrationNotistack from "./components/AlertComponent";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/autenticidade" element={<AuthenticityPage />} />

        <Route
          path="/adm"
          element={
            <PrivateRouter>
              <AdmPage />
            </PrivateRouter>
          }
        />
        <Route
          path="/adm/consultStudent"
          element={
            <PrivateRouter>
              <IntegrationNotistack>
                <StudentSearchProvider>
                  <ConsultStudentPage />
                </StudentSearchProvider>
              </IntegrationNotistack>
            </PrivateRouter>
          }
        />

        {/* <Route
          path="/user"
          element={
            <PrivateRouter>
              <UserPage />
            </PrivateRouter>
          }
        /> */}
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        <Route path="/certificado/:id" element={<CertificatePage />} />
        {/* <Route path="/forgot_password" element={<ForgotPasswordPage />} />
        <Route
          path="/forgot_password/:id/:token"
          element={<ForgotPasswordPage />}
        /> */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
