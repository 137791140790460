function SET_CERTIFICATE(value = false) {
  return { type: "SET_CERTIFICATE", payload: value };
}

function SET_SAVE_BUTTON(value = false) {
  return { type: "SET_SAVE_BUTTON", payload: value };
}

function SET_FORM_DATA(value = false) {
  return { type: "SET_FORM_DATA", payload: value };
}

function SET_LOADING_STUDENTS_DATA(value = false) {
  return { type: "SET_LOADING_STUDENTS_DATA", payload: value };
}

function SET_SEACH_STUDENTS_DATA(value = false) {
  return { type: "SET_SEACH_STUDENTS_DATA", payload: value };
}
function SET_VALIDATE_DATA_LOGIN(value = false) {
  return { type: "SET_VALIDATE_DATA_LOGIN", payload: value };
}
function GENERATING_CERTIFICATES(value = false) {
  return { type: "GENERATING_CERTIFICATES", payload: value };
}
function SET_LOADING_REGISTER_USER(value = false) {
  return { type: "SET_LOADING_REGISTER_USER", payload: value };
}

function SET_LOADING_RENAME(value = false) {
  return { type: "SET_LOADING_RENAME", payload: value };
}

function SET_LOADING_RESET_PASSWORD(value = false) {
  return { type: "SET_LOADING_RESET_PASSWORD", payload: value };
}

function SET_LOADING_AUTHENTICITY(value = false) {
  return { type: "SET_LOADING_AUTHENTICITY", payload: value };
}

export {
  SET_CERTIFICATE,
  SET_SAVE_BUTTON,
  SET_FORM_DATA,
  SET_LOADING_STUDENTS_DATA,
  SET_SEACH_STUDENTS_DATA,
  SET_VALIDATE_DATA_LOGIN,
  GENERATING_CERTIFICATES,
  SET_LOADING_REGISTER_USER,
  SET_LOADING_RENAME,
  SET_LOADING_RESET_PASSWORD,
  SET_LOADING_AUTHENTICITY,
};
