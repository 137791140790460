import FormLogin from "../components/FormLogin/Form/";
import TitleForm from "../components/FormLogin/TitleForm/";
import BoxContainer from "../components/HomeSeach/BoxContainer";
import PublicLayout from "../layouts/PublicLayout";
import AlertComponent from "../components/AlertComponent";

const LoginPage = () => {
  return (
    <PublicLayout>
      <BoxContainer>
        <TitleForm />
        <AlertComponent>
          <FormLogin />
        </AlertComponent>
      </BoxContainer>
    </PublicLayout>
  );
};

export default LoginPage;
