import React from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

import { TiArrowRightThick } from "react-icons/ti";

function AlertForm() {
  const error_state = useSelector((states) => states.error_state);

  return (
    <Alert variant="danger">
      <p>
        {Object.values(error_state).filter((value) => value === true).length >
        1 ? (
          <b>ERROS: </b>
        ) : (
          <b>ERRO: </b>
        )}
      </p>

      {error_state.titleErr ? (
        <p>
          <TiArrowRightThick className="fs-5" /> O campo <b>Título</b> não pode
          ficar em branco.
        </p>
      ) : (
        <></>
      )}
      {error_state.hoursErr ? (
        <p>
          <TiArrowRightThick className="fs-5" /> O campo <b>Carga horária</b>{" "}
          não pode ficar vazio.
        </p>
      ) : (
        <></>
      )}
      {error_state.modulesErr ? (
        <p>
          <TiArrowRightThick className="fs-5" /> Você não selecionou nenhuma
          aula dos módulos.
        </p>
      ) : (
        <></>
      )}
      {error_state.changeErr ? (
        <p>
          <TiArrowRightThick className="fs-5" /> Você não fez nenhuma alteração.
        </p>
      ) : (
        <></>
      )}
    </Alert>
  );
}

export default AlertForm;
