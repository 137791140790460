import React, { useEffect } from "react";
import Cardscertified from "../components/AdmComponents/CardsCertified/Main/";
import AdmLayout from "../layouts/AdmLayout";

// redux
import { useDispatch, useSelector } from "react-redux";

// tools
import {
  createModulesState,
  createPageState,
  removeLoadings,
} from "../tools/admFunctions";

const AdmPage = () => {
  const dispatch = useDispatch();
  const states = useSelector((state) => state);

  useEffect(() => {
    // Retiro a animação de load quando as pages forem carregadas.
    removeLoadings(dispatch, states);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  useEffect(() => {
    // Crio as pages
    createPageState(dispatch, states);
    // Crio os módulos
    createModulesState(dispatch, states);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.modules]);

  return (
    <AdmLayout>
      <Cardscertified />
    </AdmLayout>
  );
};

export default AdmPage;
