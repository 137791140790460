import React from "react";
import { FormCheck, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

function PagesCertified({ pages, id }) {
  const all_pages = useSelector((state) => state.pages);

  return (
    <div style={{ pointerEvents: "none", opacity: "0.4" }}>
      {all_pages[`${id}`].map((page, index) => (
        <Card key={index}>
          <Card.Body className="d-flex align-items-center">
            <FormCheck
              className="pe-3"
              readOnly
              checked={
                pages[`${id}`].some((value) => value.page_id === page.page_id)
                  ? true
                  : false
              }
            />
            <label>{page.name}</label>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}
export default PagesCertified;
