import { Alert } from "@mui/material";
import { Accordion, Card, Form } from "react-bootstrap";
import PendingClasses from "../PendingClasses";
import { CertificateConfirmationModal } from "../ConfirmModal";
import { useState } from "react";
import { useSearchContext } from "../../../../contexts/StudentSearchContext";

const CertificatesNotGenerated = ({ resultSearch }) => {
  const [certificateIdToForceGenerate, setCertificateIdToForceGenerate] =
    useState("");
  const [loading, setLoading] = useState(false);
  const { forceGenerateCertificate } = useSearchContext();

  async function handleForceGenerateCertificate() {
    setLoading(true);
    await forceGenerateCertificate(certificateIdToForceGenerate);
    setCertificateIdToForceGenerate("");
    setLoading(false);
  }

  return (
    <>
      <Form.Group className="my-2" controlId="certificateNotGenerate">
        <Card.Title as="h5" className="mt-3">
          Certificados pendentes
        </Card.Title>
        {resultSearch.certificates_not_generated.length ? (
          resultSearch.certificates_not_generated.map((certificate) => {
            return (
              <Accordion flush className="card" key={certificate._id}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{certificate.title}</Accordion.Header>
                  <Accordion.Body>
                    <PendingClasses
                      pages={certificate.pages}
                      progress={resultSearch.progress}
                      showModelToForceGenerateCertificate={() =>
                        setCertificateIdToForceGenerate(certificate._id)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })
        ) : (
          <Alert
            variant="outlined"
            severity="info"
            style={{ marginTop: "1rem" }}
          >
            O aluno já tem todos os certificados gerados.
          </Alert>
        )}
      </Form.Group>

      <CertificateConfirmationModal
        show={certificateIdToForceGenerate != ""}
        handleClose={() => {
          setCertificateIdToForceGenerate("");
        }}
        handleConfirm={handleForceGenerateCertificate}
        loading={loading}
      />
    </>
  );
};

export default CertificatesNotGenerated;
