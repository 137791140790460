import styles from "../../HomeSeach/TitleHome/titleHome.module.css";
import style_icon from "./titleForm.module.css";
import { GrSecure } from "react-icons/gr";

const TitleForm = () => {
  return (
    <>
      <h1 className={styles.titleHomeMobile}>
        <span className={`${styles.titleHome} ${style_icon.titleForm}`}>
          <GrSecure className={style_icon.iconMobile} />
          FAÇA SEU LOGIN
        </span>
      </h1>
    </>
  );
};

export default TitleForm;
