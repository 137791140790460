import { UNSELECTED_STATES } from "../store/actions/pagesActions";
import { SET_STATE } from "../store/actions/allSelectActions";

const selected_pages = (pages) => {
  // Função que retorna seleção das páginas que vão para o banco

  let modules_selected = {};

  Object.keys(pages).forEach((module, indice) => {
    let pages_selected = Object.values(pages)[indice].filter(
      (page) => page.isSelect
    );
    if (pages_selected.length) modules_selected[`${module}`] = pages_selected;
  });

  return modules_selected;
};

const unselected_all_pages = (pages, dispatch) => {
  // função para resetar as seleções do cadastro
  const unselected_states = {};
  Object.keys(pages).forEach((current_module) => {
    dispatch(SET_STATE({ key: current_module, value: false }));
    let unselected_pages = pages[`${current_module}`].map((page) => {
      page.isSelect = false;
      return page;
    });
    unselected_states[`${current_module}`] = unselected_pages;
  });

  dispatch(UNSELECTED_STATES(unselected_states));
};

function ValidadeEditData(oldData, newPages, newTitle, newHours) {
  if (
    oldData.title === newTitle &&
    isEquals(newPages, oldData.pages) &&
    newHours === oldData.hours
  ) {
    return true;
  }

  return false;
}

function isEquals(a, b) {
  let isEqual = true;

  if (Object.keys(a).length === Object.keys(b).length) {
    Object.keys(a).forEach((key) => {
      a[`${key}`].forEach((e, index) => {
        try {
          if (e.page_id !== b[`${key}`][index].page_id) {
            isEqual = false;
          }
        } catch (err) {
          isEqual = false;
        }
      });
    });
  } else {
    isEqual = false;
  }

  return isEqual;
}

export { selected_pages, unselected_all_pages, ValidadeEditData };
