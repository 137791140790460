import { Alert } from "@mui/material";
import { Accordion, Button, Card, Form } from "react-bootstrap";
import { UpdateCertificateModal } from "./UpdateCertificateModal";
import { useState } from "react";
import { useSearchContext } from "../../../../contexts/StudentSearchContext";
// import styles from "../BoxResult/boxResult.module.css";
// import PendingClasses from "../PendingClasses";

const CertificatesGenerated = ({ resultSearch }) => {
  const { changeStudentCertificateToUpdate } = useSearchContext();

  function formatDateINTL(date) {
    return new Intl.DateTimeFormat("pt-BR").format(date);
  }
  return (
    <div>
      <Form.Group className="my-2" controlId="certificateNotGenerate">
        <Card.Title as="h5" className="mt-3">
          Certificados gerados
        </Card.Title>
        {resultSearch.certificates_genereated.length ? (
          resultSearch.certificates_genereated.map((certificate) => {
            return (
              <Accordion flush className="card" key={certificate._id}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{certificate.moduleName}</Accordion.Header>
                  <Accordion.Body>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateRows: "auto auto",
                        gap: "16px",
                      }}
                    >
                      <div>
                        <Card.Text className="m-auto">
                          <b>Nome: </b> {certificate.studentName}
                        </Card.Text>
                        <Card.Text className="m-auto">
                          <b>Duração: </b> {certificate.hours}
                        </Card.Text>
                        <Card.Text className="m-auto">
                          <b>Data da geração: </b>
                          {formatDateINTL(new Date(certificate.createdAt))}
                        </Card.Text>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          onClick={() =>
                            changeStudentCertificateToUpdate(certificate)
                          }
                        >
                          Atualizar certificado
                        </Button>
                        <a
                          download
                          href={certificate?.mediaLink ?? ""}
                          style={{ width: "100%", display: "flex" }}
                        >
                          <Button variant="secondary" style={{ width: "100%" }}>
                            Download
                          </Button>
                        </a>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })
        ) : (
          <Alert
            variant="outlined"
            severity="info"
            style={{ marginTop: "1rem" }}
          >
            O aluno não tem nenhum certificado gerado.
          </Alert>
        )}
      </Form.Group>
    </div>
  );
};

export default CertificatesGenerated;
