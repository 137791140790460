export default function userLoggedReducer(state = {}, action) {
  switch (action.type) {
    case "GETING_DATA_USER_LOGGED":
      return action.payload;
    case "DISCONNECT_USER":
      return {};

    case "CERTIFICATES_AVAILABLE":
      return Object.assign({}, state, {
        certificates_available: action.payload,
      });
    default:
      return state;
  }
}
