export default function pagesReducer(state = {}, action) {
  switch (action.type) {
    case "GETING_PAGES":
      state[`${action.payload.id}`] = action.payload.pages;
      return Object.assign({}, state);
    case "SET_STATE_PAGE":
      state[`${action.payload.id}`] = action.payload.pages;
      return Object.assign({}, state);
    case "SELECT_ALL_PAGES":
      return action.payload;
    case "UNSELECTED_STATES":
      return action.payload;
    case "RECOVER_STATE":
      return action.payload.pages;
    default:
      return state;
  }
}
