import { SET_SEACH_STUDENTS_DATA } from "../store/actions/loadingActions";
import {
  SET_NOTHINGDATA_ERROR,
  SET_ISNOTEMAIL_ERROR,
} from "../store/actions/validationDataAction";

import { IsEmail } from "./utils";
import { all_certificates_generated } from "./userFunctions";

const validationDataInput = (email, dispatch) => {
  if (email) {
    if (!IsEmail(email)) {
      dispatch(SET_ISNOTEMAIL_ERROR(true));
      return;
    }
  } else {
    dispatch(SET_NOTHINGDATA_ERROR(true));
    return;
  }
  return true;
};

// faz a verificação caso após o click do formulário da homePage
const seachClick = (
  dispatch,
  email,
  setAnyCertified,
  setCertificatesGenerated,
  setBlockedAccount,
  anyCertified
) => {
  if (validationDataInput(email, dispatch)) {
    dispatch(SET_SEACH_STUDENTS_DATA(true));
    all_certificates_generated(email).then((res) => {
      if (anyCertified) {
        setAnyCertified(false);
      }

      if (!res) {
        setBlockedAccount(true);
        return dispatch(SET_SEACH_STUDENTS_DATA(false));
      }
      // certificados que já foram gerados
      setCertificatesGenerated(res);
      dispatch(SET_SEACH_STUDENTS_DATA(false));
      if (!res.length) {
        setAnyCertified(true);
      }
    });
  }
};

export { validationDataInput, seachClick };
