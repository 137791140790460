import React from "react";
import { useSelector } from "react-redux";
import { Accordion, Spinner, InputGroup, Card } from "react-bootstrap";
import PagesCertified from "./PagesCertified";

function ModulesCard({ certified }) {
  const all_states = useSelector((states) => states);

  return all_states.loading_states.loading_data_form && all_states.pages ? (
    <div className="d-flex justify-content-center mt-4">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      {all_states.modules.map((module, index) =>
        Object.keys(certified.pages).some(
          (value) => value === module.module_id
        ) ? (
          <Card key={index}>
            <Accordion defaultActiveKey="1" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header bsPrefix>{module.name}</Accordion.Header>
                <Accordion.Body>
                  <InputGroup className="mb-3 d-flex flex-column">
                    <PagesCertified
                      pages={certified.pages}
                      id={module.module_id}
                    />
                  </InputGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
        ) : null
      )}
    </>
  );
}
export default ModulesCard;
