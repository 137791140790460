import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { verify_and_update_states } from "../../../tools/verificateDataFunctions";
import AnyCertified from "../AnyCertified/";
import styles from "./formHome.module.css";
import { seachClick } from "../../../tools/homeSeachFunctions";
import ResultSeach from "../ResultSeach";
import { PulseLoader } from "react-spinners";

const FormHome = ({
  certificatesGenerated,
  setCertificatesGenerated,
  anyCertified,
  setAnyCertified,
}) => {
  const [blockedAccountErr, setBlockedAccount] = useState(false);
  const [email, setEmail] = useState("");

  const all_states = useSelector((states) => states);
  const dispatch = useDispatch();

  return (
    <Form className={styles.formHome} onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className={styles.labelHome}>DIGITE SEU EMAIL</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          className={styles.inputHome}
          onChange={(e) => {
            verify_and_update_states(
              all_states,
              dispatch,
              anyCertified,
              setAnyCertified,
              certificatesGenerated,
              setCertificatesGenerated,
              blockedAccountErr,
              setBlockedAccount
            );
            setEmail(e.target.value.toLowerCase());
          }}
          value={email}
          isInvalid={
            all_states.error_state.nothingDataErr ||
            all_states.error_state.isNotEmailErr ||
            blockedAccountErr
          }
        />
        <Form.Control.Feedback type="invalid" className="text-start">
          {all_states.error_state.nothingDataErr
            ? "Digite um e-mail no campo acima."
            : all_states.error_state.isNotEmailErr
            ? "O valor digitado não é um e-mail :("
            : blockedAccountErr
            ? "Por algum motivo sua conta foi bloqueada :("
            : ""}
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        className={styles.buttonHome}
        onClick={() =>
          seachClick(
            dispatch,
            email,
            setAnyCertified,
            setCertificatesGenerated,
            setBlockedAccount,
            anyCertified
          )
        }
        disabled={all_states.loading_states.seachStudent}
      >
        {all_states.loading_states.seachStudent ? (
          <>
            Consultando <PulseLoader size={10} color={"#ffffff"} />
          </>
        ) : (
          "Consultar certificados"
        )}
      </Button>

      {anyCertified ? (
        <AnyCertified text="Não existem certificados para você nesse momento. :(" />
      ) : (
        <></>
      )}
      <ResultSeach certificatesGenerated={certificatesGenerated} />
    </Form>
  );
};

export default FormHome;
