import React from "react";
// bootstrap
import { Accordion, InputGroup, Spinner } from "react-bootstrap";
// componentes
import AlertForm from "../AlertForm";
import SelectAll from "../FormRegister/SelectAll";
import PagesForm from "./PagesEditForm";
import SaveEditButton from "./SaveEditButton";
// redux
import { useSelector } from "react-redux";

function ModuleEditForm({ title, certificate_data, setShow, id, hours }) {
  const all_states = useSelector((states) => states);
  const error_state = useSelector((states) => states.error_state);

  return all_states.loading_states.loading_data_form && all_states.pages ? (
    <div className="d-flex justify-content-center mt-4">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      <label className="mt-4 mb-2 border-bottom">Módulos e aulas</label>
      {all_states.modules.map((module, index) => {
        return (
          <Accordion key={index} defaultActiveKey="1" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{module.name}</Accordion.Header>
              <Accordion.Body>
                <InputGroup className="mb-3 d-flex flex-column">
                  <SelectAll index={module.module_id} />
                  <PagesForm
                    module_id={module.module_id}
                    certificate_data={
                      certificate_data.pages[`${module.module_id}`]
                    }
                  />
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
      {error_state.modulesErr ||
      error_state.titleErr ||
      error_state.hoursErr ||
      error_state.changeErr ? (
        <AlertForm />
      ) : (
        <></>
      )}
      <SaveEditButton
        title={title}
        hours={hours}
        setShowForm={setShow}
        id={id}
        certificate_data={certificate_data}
      />
    </>
  );
}

export default ModuleEditForm;
