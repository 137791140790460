import { useState } from "react";
//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//components
import Home from "../components/HomeSeach/TitleHome";
//redux
import { Container } from "react-bootstrap";
import logo_image from "../assets/logo.114a0923.png";

//tools
import FormHome from "../components/HomeSeach/FormHome";
const HomePage = () => {
  const [certificatesGenerated, setCertificatesGenerated] = useState([]);
  const [anyCertified, setAnyCertified] = useState(false);

  return (
    <Container style={{ maxWidth: "900px", padding: "30px" }}>
      <img alt="Imagem logo do site" src={logo_image} />
      <Home />
      <FormHome
        certificatesGenerated={certificatesGenerated}
        setCertificatesGenerated={setCertificatesGenerated}
        anyCertified={anyCertified}
        setAnyCertified={setAnyCertified}
      />
    </Container>
  );
};

export default HomePage;
