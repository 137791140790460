const initialState = {
  loading_certificates: false,
  loading_save_button: false,
  loading_data_form: true,
  seachStudent: false,
  validateDataLogin: false,
  generatingCertificates: false,
  loading_register_user: false,
  loading_rename_certificates: false,
  loading_reset_password: false,
  loading_authenticity_certificate: false,
};

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CERTIFICATE":
      return Object.assign({}, state, { loading_certificates: action.payload });
    case "SET_SAVE_BUTTON":
      return Object.assign({}, state, { loading_save_button: action.payload });
    case "SET_FORM_DATA":
      return Object.assign({}, state, { loading_data_form: action.payload });
    case "SET_LOADING_REGISTER_USER":
      return Object.assign({}, state, {
        loading_register_user: action.payload,
      });
    case "SET_SEACH_STUDENTS_DATA":
      return Object.assign({}, state, { seachStudent: action.payload });
    case "SET_VALIDATE_DATA_LOGIN":
      return Object.assign({}, state, { validateDataLogin: action.payload });
    case "SET_LOADING_RENAME":
      return Object.assign({}, state, {
        loading_rename_certificates: action.payload,
      });
    case "GENERATING_CERTIFICATES":
      return Object.assign({}, state, {
        generatingCertificates: action.payload,
      });
    case "SET_LOADING_RESET_PASSWORD":
      return Object.assign({}, state, {
        loading_reset_password: action.payload,
      });
    case "SET_LOADING_AUTHENTICITY":
      return Object.assign({}, state, {
        loading_authenticity_certificate: action.payload,
      });
    default:
      return state;
  }
}
