function GETING_DATA_USER_LOGGED(value) {
  return { type: "GETING_DATA_USER_LOGGED", payload: value };
}

function DISCONNECT_USER() {
  return { type: "DISCONNECT_USER" };
}

function CERTIFICATES_AVAILABLE(value) {
  return { type: "CERTIFICATES_AVAILABLE", payload: value };
}

export {
  GETING_DATA_USER_LOGGED,
  DISCONNECT_USER,
  CERTIFICATES_AVAILABLE,
};
