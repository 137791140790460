import { getModules, getPages } from "../../tools/hotmartAPI";
import { GETING_MODULES } from "../actions/modulesActions";
import { GETING_PAGES } from "../actions/pagesActions";

function getting_all_modules() {
  return (dispatch) => {
    getModules().then((modules) => {
      dispatch(GETING_MODULES(modules));
    });
  };
}

function getting_all_pages(id, states) {
  return (dispatch) => {
    getPages(id).then((pages) => {
      let new_pages = pages.map((page) => {
        page.isSelect = false;
        return page;
      });

      dispatch(GETING_PAGES({ id, pages: new_pages }));
    });
  };
}

export { getting_all_modules, getting_all_pages };
