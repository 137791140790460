import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// tools
import { updateCertified } from "../../../tools/admFunctions";
import { selected_pages } from "../../../tools/formFunctions";

function SaveEditButton({ title, id, setShowForm, certificate_data, hours }) {
  const [show, setShow] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const pages = useSelector((states) => states.pages);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    updateCertified(
      selected_pages,
      dispatch,
      title,
      setShow,
      pages,
      certificate_data,
      setEditLoading,
      id,
      setShowForm,
      hours,
      navigate
    );
  };
  return (
    <>
      <Button
        variant="success"
        className="w-100 mt-4"
        onClick={() => setShow(true)}
        disabled={editLoading}
      >
        Editar Certificado
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você confirma as alterações feitas nesse certificado ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleClick}>
            Salvar Mudanças
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveEditButton;
