// import { Modal } from "@mui/material";
import { MyContainer, MyContent } from "./styles";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { CertificateConfirmationModal } from "../../ConfirmModal";
import { useEffect, useState } from "react";
import { BoxInputForm } from "./components/BoxInputForm";
// import { SwitchPersonalized } from "./components/SwitchPersonalized";
import { my_dayjs } from "../../../../../tools/dayjsConfig";
import { Switch } from "@mui/material";
import { useSearchContext } from "../../../../../contexts/StudentSearchContext";
// import { SwitchPersonalized } from "./components/SwitchPersonalized";

export function UpdateCertificateModal({
  show,
  handleClose,
  studentCertificate,
}) {
  const { updateCertificatePartiallyOrFully } = useSearchContext();
  //   const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateWithCurrentModelTemplate, setUpdateWithCurrentModelTemplate] =
    useState(false);

  const [studentName, setStudentName] = useState("");
  const [hoursCertificate, setHoursCertificate] = useState("");
  const [dateGeneratedCertificate, setDateGeneratedCertificate] = useState("");
  const [
    openConfirmDecisionEditCertificate,
    setOpenConfirmDecisionEditCertificate,
  ] = useState(false);

  const [disabledStudentName, setDisabledStudentName] = useState(true);
  const [disabledHoursCertificate, setDisabledHoursCertificate] =
    useState(true);
  const [
    disabledDateGeneratedCertificate,
    setDisabledDateGeneratedCertificate,
  ] = useState(true);

  const anyInputNotDisabled =
    !disabledStudentName ||
    !disabledHoursCertificate ||
    !disabledDateGeneratedCertificate;

  function handleOpenConfirmDecisionModal() {
    setOpenConfirmDecisionEditCertificate(true);
  }

  function handleCloseConfirmDecisionModal() {
    setOpenConfirmDecisionEditCertificate(false);
  }

  async function editCertificate() {
    if (studentCertificate) {
      const dateCreatedAt = my_dayjs(dateGeneratedCertificate).toISOString();
      setLoading(true);
      await updateCertificatePartiallyOrFully({
        dateGenerated: dateCreatedAt,
        hoursCertificate,
        studentName,
        updateByCurrentTemplate: updateWithCurrentModelTemplate,
      });
      handleCloseConfirmDecisionModal();
      setLoading(false);
      handleClose();
    }
  }

  useEffect(() => {
    if (show && studentCertificate) {
      setStudentName(studentCertificate?.studentName);
      setHoursCertificate(`${studentCertificate?.hours}`);

      const formattedDate = my_dayjs(studentName.createdAt).format(
        "YYYY-MM-DD"
      );
      setDateGeneratedCertificate(formattedDate);
      setUpdateWithCurrentModelTemplate(false);
    }
  }, [show, studentCertificate]);

  function handleDisabledStudentName() {
    setDisabledStudentName((currentState) => !currentState);
  }

  function handleDisabledHoursCertificate() {
    setDisabledHoursCertificate((currentState) => !currentState);
  }

  function handleDisabledDateGeneratedCertificate() {
    setDisabledDateGeneratedCertificate((currentState) => !currentState);
  }

  function changeStudentName(newValue) {
    setStudentName(newValue);
  }
  function changeHoursCertificate(newValue) {
    setHoursCertificate(newValue);
  }
  function changedateGeneratedCertificate(newValue) {
    setDateGeneratedCertificate(newValue);
  }

  function changeValueUpdateWithCurrentModelTemplate() {
    setUpdateWithCurrentModelTemplate((currentState) => !currentState);
  }

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        // onFocus={false}

        // disableAutoFocus
        // disableAutoFocus={true}
        // onBackdropClick={undefined}
      >
        <MyContainer>
          <MyContent>
            <div className="my-header">
              <Card.Title className="mb-auto" as="h4">
                Dados do certificado
              </Card.Title>
            </div>
            <div className="my-body">
              {/* <BoxInputForm /> */}
              {/* <Card.Text className="mb-auto">
                Confirme sua decisão selecionando um dos botões abaixo:
              </Card.Text> */}

              <BoxInputForm
                type="name"
                state={studentName}
                disabled={disabledStudentName}
                handleChange={changeStudentName}
                handleDisabled={handleDisabledStudentName}
                initialStateValue={studentCertificate?.studentName}
              />
              <BoxInputForm
                type="hours"
                state={hoursCertificate}
                disabled={disabledHoursCertificate}
                handleChange={changeHoursCertificate}
                handleDisabled={handleDisabledHoursCertificate}
                initialStateValue={studentCertificate?.hours}
              />
              <BoxInputForm
                type="date"
                state={dateGeneratedCertificate}
                disabled={disabledDateGeneratedCertificate}
                handleChange={changedateGeneratedCertificate}
                handleDisabled={handleDisabledDateGeneratedCertificate}
                initialStateValue={studentCertificate?.createdAt}
              />

              <p
                className="obs-p"
                style={{ fontSize: "0.875rem", marginTop: "1rem" }}
              >
                *Caso nenhum dado tenha sido editado, a atualização só irá
                forçar uma nova geração da imagem utilizando os mesmos dados
                acima
              </p>

              {/* <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                // label="Check this switch"
              /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label htmlFor="switch-label">
                  Pegar dados atuais do modal
                </label>
                <Switch
                  id="switch-label"
                  onChange={changeValueUpdateWithCurrentModelTemplate}
                  checked={updateWithCurrentModelTemplate}
                  defaultChecked
                />
              </div>

              {/* <SwitchPersonalized
                value={updateWithCurrentModelTemplate}
                handleChangeValue={changeValueUpdateWithCurrentModelTemplate}
              /> */}
            </div>
            <div className="my-fotter">
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={loading || anyInputNotDisabled}
                //   onClick={handleConfirm}
                onClick={handleOpenConfirmDecisionModal}
              >
                Atualizar
              </Button>
            </div>
          </MyContent>
        </MyContainer>
      </Modal>
      <CertificateConfirmationModal
        show={openConfirmDecisionEditCertificate}
        handleClose={handleCloseConfirmDecisionModal}
        loading={loading}
        handleConfirm={editCertificate}
      />
    </>
  );
}
