import {
  SET_NOTHINGDATA_ERROR,
  SET_ISNOTEMAIL_ERROR,
  SET_NOTHING_PASSWORD_DATA_ERROR,
  SET_LOGIN_FAILED_ERROR,
} from "../store/actions/validationDataAction";

const verify_and_update_states = (
  all_states,
  dispatch,
  anyCertified,
  setAnyCertified,
  certificatesGenerated,
  setCertificatesGenerated,
  blockedAccountErr,
  setBlockedAccount
) => {
  if (all_states.error_state.isNotEmailErr) dispatch(SET_ISNOTEMAIL_ERROR());
  if (all_states.error_state.nothingDataErr) dispatch(SET_NOTHINGDATA_ERROR());
  if (anyCertified) setAnyCertified(false);
  if (all_states.error_state.nothingPasswordDataErr)
    dispatch(SET_NOTHING_PASSWORD_DATA_ERROR());
  if (all_states.error_state.loginFailedErr) dispatch(SET_LOGIN_FAILED_ERROR());
  if (certificatesGenerated) {
    if (certificatesGenerated.length) setCertificatesGenerated([]);
  }
  if (blockedAccountErr) setBlockedAccount(false);
};

export { verify_and_update_states };
