import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";

import imagem from "../../assets/logo.114a0923.png";
import AdmButton from "./AdmButton/";

function Header() {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand className="d-flex align-items-center">
            <img
              alt="Imagem logo do site"
              src={imagem}
              width="60"
              height="60"
              className="me-2 animationIcon"
            />
            ADM PROGBR
          </Navbar.Brand>
        </Link>
        <AdmButton />
      </Container>
    </Navbar>
  );
}

export default Header;
