import React, { useState } from "react";
// bootstrap
import { Accordion, Form, InputGroup, Spinner } from "react-bootstrap";
// icons
import { FcHighPriority } from "react-icons/fc";
// componentes
import SelectAll from "./SelectAll";
import PagesForm from "./PagesForm";
import ButtonsForm from "./ButtonsForm";
import AlertForm from "../AlertForm";
// redux
import { useDispatch, useSelector } from "react-redux";
import { SET_HOURS_ERROR } from "../../../store/actions/validationDataAction";

function ModuleForm(props) {
  const all_states = useSelector((states) => states);
  const error_state = useSelector((states) => states.error_state);
  const dispatch = useDispatch();
  const [hours, setHours] = useState("");

  return all_states.loading_states.loading_data_form && all_states.pages ? (
    <div className="d-flex justify-content-center mt-4">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      <Form.Group controlId="hours">
        <Form.Label className="border-bottom">Carga horária</Form.Label>
        <Form.Control
          onInput={(e) => {
            if (error_state.hoursErr) dispatch(SET_HOURS_ERROR(false));

            setHours(() => e.target.value);
          }}
          type="number"
          placeholder="Carga horária total do certificado"
          isInvalid={error_state.hoursErr}
          value={hours}
        />
      </Form.Group>
      <label className="mt-4 mb-2 border-bottom">Módulos e aulas</label>
      {all_states.modules.map((module, index) => {
        return (
          <Accordion key={index} defaultActiveKey="1" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header bsPrefix>
                {error_state.modulesErr ? (
                  <FcHighPriority className="me-2" />
                ) : null}
                <span className={error_state.modulesErr ? "text-danger" : null}>
                  {module.name}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <InputGroup className="mb-3 d-flex flex-column">
                  <SelectAll index={module.module_id} />
                  <PagesForm module_id={module.module_id} />
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}

      {error_state.modulesErr ||
      error_state.titleErr ||
      error_state.hoursErr ? (
        <AlertForm />
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-between mt-4">
        <ButtonsForm
          title={props.title}
          setTitle={props.setTitle}
          setShow={props.setShow}
          hours={hours}
        />
      </div>
    </>
  );
}

export default ModuleForm;
