import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BoxCertificateImage from "../components/BoxCertificateImage/";

const Certificate_page = () => {
  const params = useParams();
  const [mediaLink, setMediaLink] = useState("");

  useEffect(() => {
    fetch("/api/certified/" + params.id)
      .then((http) => http.json())
      .then((res) => setMediaLink(res));
    // eslint-disable-next-line
  }, []);

  return (
    <BoxCertificateImage url={mediaLink}>
      {mediaLink.length ? (
        <img
          src={mediaLink}
          style={{ width: "100%" }}
          alt="Imagem do cerficado do aluno programadorbr"
        />
      ) : (
        <></>
      )}
    </BoxCertificateImage>
  );
};

export default Certificate_page;
