import { Card, Form } from "react-bootstrap";

import CertificatesNotGenerated from "../CertificatesNotGenerated";
import ProgressStudent from "../ProgressStudent";
import styles from "./boxResult.module.css";
import CertificatesGenerated from "../CertificatesGenerated";

const BoxResult = ({ resultSearch }) => {
  function formatDateINTL(date) {
    return new Intl.DateTimeFormat("pt-BR").format(date);
  }

  return (
    <div className={styles.containerResult}>
      <Card.Title as="h3">Dados do aluno</Card.Title>

      <Card.Text className="m-auto">
        <b>Nome: </b> {resultSearch?.user.name}
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Situação:</b> {resultSearch?.user.status}
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Tipo de conta:</b> {resultSearch?.user.type}
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Progresso: </b>
        {resultSearch?.user.progress.completed_percentage}%
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Número de acessos: </b> {resultSearch?.user.access_count}
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Engajamento: </b> {resultSearch?.user.engagement}
      </Card.Text>
      <Card.Text className="m-auto">
        <b>Data da compra: </b>
        {resultSearch?.user.purchase_date
          ? formatDateINTL(resultSearch?.user.purchase_date)
          : "UNDEFINED"}
      </Card.Text>

      <Form onSubmit={(e) => e.preventDefault()} className={styles.formSeach}>
        <ProgressStudent resultSearch={resultSearch} />
        <CertificatesNotGenerated resultSearch={resultSearch} />
        <CertificatesGenerated resultSearch={resultSearch} />
      </Form>
    </div>
  );
};

export default BoxResult;
