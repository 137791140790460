import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { FaUserCircle } from "react-icons/fa";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { user_disconnect } from "../../../tools/utils";
import { IconButton } from "@mui/material";
import styles from "./AdmButton.module.css";
import ButtonAddcertified from "../ButtonAddCertified";
import GenerateCertificate from "../../GenerateCertificate/";
import { generateCertificates } from "../../../tools/admFunctions";

const UserButton = () => {
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="default"
        className={styles.buttonMobile}
      >
        <FaUserCircle style={{ fontSize: 45, color: "white" }} />
      </IconButton>
      <button
        className={`btn dropdown-toggle ${styles.buttonDesktop}`}
        type="button"
        onClick={handleClick}
      >
        <b>ADMINISTRATIVO</b>
      </button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ textAlign: "center" }}
      >
        {location.pathname === "/adm" ? (
          <div>
            <MenuItem
              onClick={() => setShow(true)}
              style={{ display: "inline-block", width: "100%" }}
            >
              REGISTRAR CERTIFICADO
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() => navigate("/adm/consultStudent")}
              style={{ display: "inline-block", width: "100%" }}
            >
              CONSULTAR ALUNO
            </MenuItem>
            <Divider />

            <MenuItem
              onClick={() => user_disconnect(dispatch, navigate)}
              style={{ display: "inline-block", width: "100%" }}
            >
              SAIR
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem
              onClick={() => navigate("/adm")}
              style={{ display: "inline-block", width: "100%" }}
            >
              HOME ADMINISTRATIVO
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => generateCertificates(dispatch, navigate)}
              style={{ display: "inline-block", width: "100%" }}
            >
              GERAR CERTIFICADOS
            </MenuItem>
            <Divider />

            <MenuItem
              onClick={() => user_disconnect(dispatch, navigate)}
              style={{ display: "inline-block", width: "100%" }}
            >
              SAIR
            </MenuItem>
          </div>
        )}
      </Menu>
      <ButtonAddcertified show={show} setShow={setShow} />
      <GenerateCertificate />
    </div>
  );
};

export default UserButton;
