import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { UpdateCertificateModal } from "../components/AdmComponents/ConsultStudent/CertificatesGenerated/UpdateCertificateModal";

export const StudentSearchContext = createContext();

export function StudentSearchProvider({ children }) {
  const [currentStudetEmail, setCurrentStudentEmail] = useState("");
  const [studentCertificateToUpdate, setStudentCertificateToUpdate] =
    useState(null);
  const [resultSearch, setResultSerach] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  function changeCurrentStudentEmail(value) {
    setCurrentStudentEmail(value);
  }

  function changeCurrentResultSearch(newValue) {
    setResultSerach(newValue);
  }
  function changeStudentCertificateToUpdate(newValue) {
    setStudentCertificateToUpdate(newValue);
  }

  async function updateCertificatePartiallyOrFully({
    dateGenerated,
    hoursCertificate,
    studentName,
    updateByCurrentTemplate,
  }) {
    try {
      await fetch(`/api/updateCertificatePartiallyOrFully`, {
        method: "PUT",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          templateCertificateId:
            studentCertificateToUpdate?.templateCertificateId,
          dateGenerated,
          hoursCertificate,
          studentName,
          updateByCurrentTemplate,
          studentEmail: studentCertificateToUpdate?.studentEmail,
          certificateId: studentCertificateToUpdate?._id,
        }),
      });
      await getUpdatedResultSearch();

      enqueueSnackbar("Certificado atualizado com sucesso =)", {
        variant: "success",
      });
    } catch (error) {
      const { response, message } = error;

      enqueueSnackbar(
        response?.data?.message || message || "Ocorreu algum erro =(",
        {
          variant: "error",
        }
      );
    } finally {
      setStudentCertificateToUpdate(null);
    }
  }

  async function getUpdatedResultSearch() {
    try {
      const http = await fetch("/api/userSeach", {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ email: currentStudetEmail }),
      });
      const res = await http.json();
      console.log("oláaa");
      setResultSerach(Object.assign(() => res));
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.error(err);
    }
  }

  async function forceGenerateCertificate(id_certificate) {
    try {
      await fetch("/api/force_certificate_generation/" + id_certificate, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ email: currentStudetEmail }),
      });

      await getUpdatedResultSearch();

      enqueueSnackbar("Certificado gerado com sucesso =)", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.error(err);
    }
  }

  return (
    <StudentSearchContext.Provider
      value={{
        currentStudetEmail,
        changeCurrentStudentEmail,
        resultSearch,
        changeCurrentResultSearch,
        forceGenerateCertificate,
        studentCertificateToUpdate,
        changeStudentCertificateToUpdate,
        updateCertificatePartiallyOrFully,
      }}
    >
      <>
        {children}
        <UpdateCertificateModal
          show={studentCertificateToUpdate != null}
          handleClose={() => changeStudentCertificateToUpdate(null)}
          studentCertificate={studentCertificateToUpdate}
        />
      </>
    </StudentSearchContext.Provider>
  );
}

export const useSearchContext = () => useContext(StudentSearchContext);
