import { Button } from "react-bootstrap";
import { MyContainer } from "./styles";
import { Check, PencilSimple, X } from "phosphor-react";
import { my_dayjs } from "../../../../../../../tools/dayjsConfig";
import { useEffect, useRef } from "react";

export function BoxInputForm({
  type,
  state,
  disabled,
  handleDisabled,
  initialStateValue,
  handleChange,
}) {
  const inputRef = useRef(null);

  function getParagraphValue() {
    switch (type) {
      case "name":
        return "Nome";
      case "hours":
        return "Carga horária";
      case "date":
        return "Data de geração";
      default:
        return "";
    }
  }

  function handleChangeNameOrHour(e) {
    const value = e.target.value;

    handleChange(value);
  }

  function handleChangeDate(e) {
    const value = e.target.value;
    const formattedDate = my_dayjs(value).format("YYYY-MM-DD");

    handleChange(formattedDate);
  }

  function handleResetData() {
    if (type === "date") {
      const formattedDate = my_dayjs(initialStateValue).format("YYYY-MM-DD");
      handleChange(formattedDate);
    } else {
      handleChange(initialStateValue);
    }

    handleDisabled();
  }

  useEffect(() => {
    if (type === "date") {
      setTimeout(() => {
        const formattedDate = my_dayjs(initialStateValue).format("YYYY-MM-DD");
        handleChange(formattedDate);
      }, 125);
    } else {
      handleChange(initialStateValue);
    }
    if (!disabled) handleDisabled();
  }, []);

  return (
    <MyContainer>
      <p className="paragraph-label">{getParagraphValue()}</p>
      <div className="box-input">
        {type === "date" ? (
          <input
            type="date"
            value={state}
            disabled={disabled}
            onChange={handleChangeDate}
          />
        ) : type === "hours" ? (
          <input
            type="number"
            min={0}
            value={state}
            disabled={disabled}
            onChange={handleChangeNameOrHour}
          />
        ) : (
          <input
            type="text"
            value={state}
            disabled={disabled}
            onChange={handleChangeNameOrHour}
          />
          // <input
          //   type="text"
          //   value={state}
          //   disabled={disabled}
          //   onChange={handleChangeNameOrHour}
          // />
        )}

        <div className="buttons-input">
          {disabled ? (
            <Button onClick={handleDisabled}>
              <PencilSimple size={24} />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleDisabled}>
                <Check size={24} />
              </Button>
              <Button variant="outline-secondary" onClick={handleResetData}>
                <X size={24} />
              </Button>
            </>
          )}
        </div>
      </div>
    </MyContainer>
  );

  // return <MyContainer>teste</MyContainer>;
}
