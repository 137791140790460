import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ModulesForm from "./ModulesForm";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../tools/admFunctions";

function Formcertified({ setShow }) {
  const error_state = useSelector((states) => states.error_state);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");

  const changeInput = (e) => {
    changeTitle(e, dispatch, error_state, setTitle);
  };

  return (
    <Form method="GET" onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="border-bottom">Título</Form.Label>
        <Form.Control
          onInput={changeInput}
          type="text"
          placeholder="Nome do certificado"
          isInvalid={error_state.titleErr}
          value={title}
        />
      </Form.Group>
      <ModulesForm setShow={setShow} title={title} setTitle={setTitle} />
    </Form>
  );
}

export default Formcertified;
