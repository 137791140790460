import Swal from "sweetalert2";
import { SET_SEACH_USER_ERROR } from "../store/actions/validationDataAction";
import { IsEmail, user_with_expired_token } from "./utils";

const seachClick = async (
  email,
  dispatch,
  all_errs,
  setLoading,
  changeCurrentResultSearch,
  setShow,
  setIsNotStudent,
  navigate
) => {
  if (email === "") dispatch(SET_SEACH_USER_ERROR({ nothingDataErr: true }));
  else if (!IsEmail(email)) {
    dispatch(SET_SEACH_USER_ERROR({ isNotEmailErr: true }));
  }
  const allErrs = Object.values(all_errs);
  if (allErrs.filter((err) => !err).length === allErrs.length) {
    setLoading(true);
    const http = await fetch("/api/userSeach", {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({ email }),
    });
    const res = await http.json();
    if (http.status === 200) {
      changeCurrentResultSearch(res);
      setShow(true);
    } else {
      if (res === "access refused") user_with_expired_token(dispatch, navigate);

      if (res === "user not found") setIsNotStudent(true);
    }

    setLoading(false);
  }
};

// atualiza nome dos certificados do aluno pesquisado.
const buttonClick = async (setLoading, dispatch, navigate, resultSearch) => {
  setLoading(true);
  const http = await fetch("/api/update_name_user", {
    method: "POST",
    headers: new Headers({ "Content-type": "application/json" }),
    body: JSON.stringify({ email: resultSearch.user.email }),
  });
  const res = await http.json();
  if (http.status === 200) {
    if (res === "user updated successfully :)") {
      Swal.fire({
        icon: "success",
        title: "ALTERAÇÃO CONCLUÍDA :)",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "NÃO HÁ ALTERAÇÕES PARA SEREM FEITAS :(",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    }
  } else {
    if (res === "access refused") {
      user_with_expired_token(dispatch, navigate);
    } else {
      Swal.fire({
        icon: "error",
        title: "ocorreu algum erro :(",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    }
  }

  setLoading(false);
};

const generateButtonClick = async (
  setLoading,
  dispatch,
  navigate,
  resultSearch
) => {
  setLoading(true);
  const http = await fetch("/api/register_and_generate_certified", {
    method: "POST",
    headers: new Headers({ "Content-type": "application/json" }),
    body: JSON.stringify({
      ...resultSearch.user,
      progress: resultSearch.progress,
    }),
  });
  const res = await http.json();
  if (http.status === 200) {
    if (res === "success generate") {
      Swal.fire({
        icon: "success",
        title: "GERAÇÃO CONCLUÍDA :)",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "NÃO HÁ CERTIFICADOS DISPONÍVEIS PARA GERAÇÃO :(",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    }
  } else {
    if (res === "access refused") {
      user_with_expired_token(dispatch, navigate);
    } else {
      Swal.fire({
        icon: "error",
        title:
          res === "User blocked"
            ? "Usuário bloqueado"
            : "ocorreu algum erro :(",
        showConfirmButton: true,
        confirmButtonText: "Entendi",
        allowOutsideClick: false,
      });
    }
  }

  setLoading(false);
};

export { seachClick, buttonClick, generateButtonClick };
