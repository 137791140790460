function SET_TITLE_ERROR(value = false) {
  return { type: "SET_TITLE_ERROR", payload: value };
}

function SET_MODULES_ERROR(value = false) {
  return { type: "SET_MODULES_ERROR", payload: value };
}

function SET_HOURS_ERROR(value = false) {
  return { type: "SET_HOURS_ERROR", payload: value };
}

function SET_CHANGE_ERROR(value = false) {
  return { type: "SET_CHANGE_ERROR", payload: value };
}

function SET_ISNOTEMAIL_ERROR(value = false) {
  return { type: "SET_ISNOTEMAIL_ERROR", payload: value };
}

function SET_NOTHINGDATA_ERROR(value = false) {
  return { type: "SET_NOTHINGDATA_ERROR", payload: value };
}

function SET_NOTHING_PASSWORD_DATA_ERROR(value = false) {
  return { type: "SET_NOTHING_PASSWORD_DATA_ERROR", payload: value };
}

function SET_LOGIN_FAILED_ERROR(value = false) {
  return { type: "SET_LOGIN_FAILED_ERROR", payload: value };
}

function SET_USER_FORM_ERROR(value) {
  return { type: "SET_USER_FORM_ERROR", payload: value };
}

function SET_USER_RESET_PASSWORD_ERROR(value) {
  return { type: "SET_USER_RESET_PASSWORD_ERROR", payload: value };
}
function SET_FORGOT_PASSWORD_ERROR(value) {
  return { type: "SET_FORGOT_PASSWORD_ERROR", payload: value };
}

function SET_SEACH_USER_ERROR(value = false) {
  return { type: "SET_SEACH_USER_ERROR", payload: value };
}

export {
  SET_TITLE_ERROR,
  SET_MODULES_ERROR,
  SET_HOURS_ERROR,
  SET_CHANGE_ERROR,
  SET_ISNOTEMAIL_ERROR,
  SET_NOTHINGDATA_ERROR,
  SET_NOTHING_PASSWORD_DATA_ERROR,
  SET_LOGIN_FAILED_ERROR,
  SET_USER_FORM_ERROR,
  SET_USER_RESET_PASSWORD_ERROR,
  SET_FORGOT_PASSWORD_ERROR,
  SET_SEACH_USER_ERROR
};
