import styles from "./anyCertified.module.css";

function AnyCertified({text}) {
  return (
    <span className={styles.anyCertifiedHome}>
        {text}
    </span>
  );
}

export default AnyCertified;
