export default function allSelectReducer(state = {}, action) {
  switch (action.type) {
    case "SET_STATE":
      state[`${action.payload.key}`] = action.payload.value;
      return Object.assign({}, state);
      case "RECOVER_STATE":
      return action.payload.allSelect;
    default:
      return state;
  }
}
