import Swal from "sweetalert2";
import { DISCONNECT_USER } from "../store/actions/userLoggedActions";

const user_disconnect = (dispatch, navigate) => {
  fetch("/api/logout").then((res) => {
    if (res.status === 200) {
      dispatch(DISCONNECT_USER());
      localStorage.clear();
      navigate("/login");
    }
  });
};

const user_with_expired_token = (dispatch, navigate) => {
  Swal.fire({
    icon: "error",
    title: "Token de acesso invalido ou expirado.",
    html: "Por motivo de segurança o login é valido durante algum tempo, caso necessário, faça login novamente.",
    allowOutsideClick: false,
    confirmButtonText: "Entendi",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      user_disconnect(dispatch, navigate);
    }
  });
};

const isStrongPassword = (str) => {
  if (str.length < 6) {
    return "Senha deve ter mais de 6 dígitos";
  } else if (str.length > 50) {
    return "Senha grande demais :(";
  } else if (str.search(/\d/) === -1) {
    return "A senha precisa ter no mínimo 1 número.";
  } else if (str.search(/[a-zA-Z]/) === -1) {
    return "A senha precisa ter no mínimo 1 letra.";
  }
  return true;
};

function IsEmail(email) {
  var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  if (reg.test(email)) {
    return true;
  } else {
    return false;
  }
}

export { user_disconnect, isStrongPassword, IsEmail, user_with_expired_token };
