import { combineReducers } from "redux";

import loadingReducer from "./reducers/loadingReducer";
import modulesReducer from "./reducers/modulesReducer";
import pagesReducer from "./reducers/pagesReducer";
import allSelectReducer from "./reducers/allSelectReducer";
import initialStatesReducer from "./reducers/initialStatesReducer";
import validationDataReducer from "./reducers/validationDataReducer";
import userLoggedReducer from "./reducers/userLoggedReducer";

const all_reducers = combineReducers({
  loading_states: loadingReducer,
  modules: modulesReducer,
  pages: pagesReducer,
  allSelect: allSelectReducer,
  initial_states: initialStatesReducer,
  error_state: validationDataReducer,
  user_logged_data: userLoggedReducer,
});

export default all_reducers;
