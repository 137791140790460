import styles from "./boxContainer.module.css";
import { Container } from "react-bootstrap";

const BoxContainer = ({ children, isFullScreen }) => {
  return (
    <Container className="pt-3">
      <div
        className={`${styles.containerHome} rounded ${
          isFullScreen ? styles.containerFullScreen : ""
        }`}
      >
        {children}
      </div>
    </Container>
  );
};

export default BoxContainer;
