// import Swal from "sweetalert2";
// import {
  // GENERATING_CERTIFICATES,
  // SET_LOADING_RESET_PASSWORD,
// } from "../store/actions/loadingActions";
// import { CERTIFICATES_AVAILABLE } from "../store/actions/userLoggedActions";
// import { SET_USER_RESET_PASSWORD_ERROR } from "../store/actions/validationDataAction";
// import { isStrongPassword, user_with_expired_token } from "./utils";

// Pega os certificados que já foram gerados
const all_certificates_generated = async (email) => {
  const http = await fetch("/api/generate_and_getting_all_certificate", {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify({ email }),
  });
  if (http.status === 400) return false;

  const res = await http.json();
  return res;
};




// const seach_and_get_user_data = (
//   email,
//   user_logged_data,
//   dispatch,
//   setAnyCertified,
//   navigate
// ) => {
//   all_certificates_generated(email, user_logged_data).then((res) => {
//     if (res !== "access refused") {
//       dispatch(CERTIFICATES_AVAILABLE(res));
//       if (!res.length) setAnyCertified(true);
//     } else {
//       user_with_expired_token(dispatch, navigate);
//     }
//   });
// };

// const reseatPasswordClick = async (
//   currentPassword,
//   newPassword,
//   dispatch,
//   setValidatePassword,
//   repeatPassword,
//   all_errors,
//   enqueueSnackbar,
//   resetInputs,
//   navigate
// ) => {
//   if (currentPassword === "") {
//     dispatch(
//       SET_USER_RESET_PASSWORD_ERROR({
//         currentPasswordNothingData: true,
//       })
//     );
//   } else if (currentPassword === newPassword && currentPassword !== "") {
//     dispatch(
//       SET_USER_RESET_PASSWORD_ERROR({
//         isSameCurrentPassword: true,
//       })
//     );
//   }

//   const isOkPassword =
//     newPassword === "" ? null : isStrongPassword(newPassword);
//   if (newPassword === "") {
//     dispatch(
//       SET_USER_RESET_PASSWORD_ERROR({
//         newPasswordNothingData: true,
//       })
//     );
//   } else if (isOkPassword !== true) {
//     setValidatePassword(() => isOkPassword);
//     dispatch(
//       SET_USER_RESET_PASSWORD_ERROR({
//         isNotPasswordValid: true,
//       })
//     );
//   } else if (newPassword !== repeatPassword && newPassword !== "") {
//     // erro senhas diferentes
//     dispatch(
//       SET_USER_RESET_PASSWORD_ERROR({
//         isNotSamePassword: true,
//       })
//     );
//   }
//   const allErrs = Object.values(all_errors);
//   if (allErrs.filter((err) => !err).length === allErrs.length) {
//     dispatch(SET_LOADING_RESET_PASSWORD(true));
//     const http = await fetch("/api/reset_password", {
//       method: "POST",
//       headers: new Headers({ "Content-Type": "application/json" }),
//       body: JSON.stringify({ currentPassword, newPassword }),
//     });
//     if (http.status !== 200) {
//       const res = await http.json();
//       if (res === "access refused") {
//         return user_with_expired_token(dispatch, navigate);
//       }
//       console.log("err", res);
//     }
//     dispatch(SET_LOADING_RESET_PASSWORD(false));
//     resetInputs();
//     enqueueSnackbar(
//       http.status === 200
//         ? "Senha alterada com sucesso :)"
//         : http.status === 401
//         ? "Senha atual incorreta"
//         : "Ocorreu um erro :(",
//       {
//         variant: http.status === 200 ? "success" : "error",
//         anchorOrigin: {
//           vertical: "bottom",
//           horizontal: "right",
//         },
//       }
//     );
//   }
// };

export {
  all_certificates_generated,
  // generatingAllCertificates,
  // seach_and_get_user_data,
  // generateCertificateClick,
  // reseatPasswordClick,
};
