import React from "react";
// componentes
import EditCertified from "../FormEdit/EditCertified";
import PopoverConfirmation from "./PopoverConfirmation";
// redux
import { useDispatch } from "react-redux";
import { SET_CERTIFICATE } from "../../../store/actions/loadingActions";
// hotmart functions
import { deleteCertified } from "../../../tools/hotmartAPI";
import { useNavigate } from "react-router-dom";

function ButtonActions({ id, certificate_data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function delete_click() {
    dispatch(SET_CERTIFICATE(true));
    deleteCertified(id, dispatch, navigate).then((res) => {
      dispatch(SET_CERTIFICATE(false));
    });
  }

  return (
    <div className="d-flex align-items-start">
      <EditCertified certificate_data={certificate_data} id={id} />
      <PopoverConfirmation delete_click={delete_click} />
    </div>
  );
}

export default ButtonActions;
