import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRouter = ({ children }) => {
  const location = useLocation().pathname;
  const isAuth = useSelector((state) => state.user_logged_data);

  return !Object.keys(isAuth).length ? (
    <Navigate to="/login" />
  ) : location === "/adm" && isAuth.isAdmin ? (
    children
  ) : location !== "/adm" && isAuth.isAdmin !== undefined ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRouter;
