import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";

import imagem from "../../../assets/logo.114a0923.png";
import styles from "./header.module.css";
// import UserButton from "../../UserButton/";
// import { useSelector } from "react-redux";

function HeaderHome() {
  // const all_states = useSelector((states) => states);
  // const pathName = useLocation().pathname;

  return (
    <Navbar className={styles.HeaderHomer}>
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand className="d-flex align-items-center">
            <img
              alt="Imagem logo do site"
              src={imagem}
              width="60"
              height="60"
              className={`me-2 animationIcon ${styles.logo}`}
            />
          </Navbar.Brand>
        </Link>
        {/* {pathName !== "/login" &&
        !Object.keys(all_states.user_logged_data).length ? (
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Button variant="primary" className={styles.loginHome}>
              Login
            </Button>
          </Link>
        ) : Object.keys(all_states.user_logged_data).length ? (
          <UserButton />
        ) : (
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button variant="primary" className={styles.loginHome}>
              Home
            </Button>
          </Link>
        )} */}
      </Container>
    </Navbar>
  );
}

export default HeaderHome;
