import styles from "./titleHome.module.css";

const TitleHome = () => {
  return (
    <h1 className={styles.titleHomeMobile}>
      <span className={styles.titleHome}>
        CURSO <br />
        DESENVOLVIMENTO <br />
        WEB FULL STACK
      </span>
    </h1>
  );
};

export default TitleHome;
