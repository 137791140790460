import React from "react";
// bootstrap
import { Button } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
// actions
import {
  SET_CERTIFICATE,
  SET_SAVE_BUTTON,
} from "../../../store/actions/loadingActions";
import {
  SET_HOURS_ERROR,
  SET_MODULES_ERROR,
  SET_TITLE_ERROR,
} from "../../../store/actions/validationDataAction";
// hotmart functions
import { savecertified } from "../../../tools/hotmartAPI";
// ferramentas
import {
  selected_pages,
  unselected_all_pages,
} from "../../../tools/formFunctions";
import { user_with_expired_token } from "../../../tools/utils";
import { useNavigate } from "react-router-dom";

function ButtonsForm(props) {
  const pages = useSelector((states) => states.pages);

  const loading_save_button = useSelector(
    (state) => state.loading_states
  ).loading_save_button;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function save_certified(selected_pages) {
    savecertified(props.title, selected_pages, props.hours).then((res) => {
      if (res === "access refused") {
        user_with_expired_token(dispatch, navigate);
      }
      dispatch(SET_SAVE_BUTTON(false));
      dispatch(SET_CERTIFICATE(false));
      props.setShow(false);
    });
  }

  const save_handleClick = () => {
    // pequena "validação" se houve ou não os dados esperados preenchidos.
    const pages_to_save = selected_pages(pages);

    if (!props.title || !Object.keys(pages_to_save).length || !props.hours) {
      if (!props.title) dispatch(SET_TITLE_ERROR(true));
      if (!Object.keys(pages_to_save).length) dispatch(SET_MODULES_ERROR(true));
      if (!props.hours) dispatch(SET_HOURS_ERROR(true));
    } else {
      dispatch(SET_SAVE_BUTTON(true));
      dispatch(SET_CERTIFICATE(true));
      save_certified(pages_to_save);
    }
  };

  const reset_handleClick = () => {
    props.setTitle("");
    unselected_all_pages(pages, dispatch);
    dispatch(SET_TITLE_ERROR(false));
    dispatch(SET_MODULES_ERROR(false));
    dispatch(SET_HOURS_ERROR(false));
  };

  return (
    <>
      <Button onClick={reset_handleClick} variant="outline-secondary">
        Resetar
      </Button>
      <Button
        variant="primary"
        disabled={loading_save_button}
        onClick={!loading_save_button ? save_handleClick : null}
      >
        {loading_save_button ? "Carregando…" : "Salvar"}
      </Button>
    </>
  );
}

export default ButtonsForm;
