function GETING_PAGES(value) {
  return { type: "GETING_PAGES", payload: value };
}

function SET_STATE_PAGE(value) {
  return { type: "SET_STATE_PAGE", payload: value };
}

function SELECT_ALL_PAGES(value) {
  return { type: "SELECT_ALL_PAGES", payload: value };
}

function UNSELECTED_STATES(value) {
  return { type: "UNSELECTED_STATES", payload: value };
}

function RECOVER_STATE(value) {
  return { type: "RECOVER_STATE", payload: value };
}

export {
  GETING_PAGES,
  SET_STATE_PAGE,
  SELECT_ALL_PAGES,
  UNSELECTED_STATES,
  RECOVER_STATE,
};
