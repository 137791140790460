import React, { useEffect } from "react";
// Bootstrap
import { Offcanvas } from "react-bootstrap";
// Icons
import { GrCertificate } from "react-icons/gr";

// Componente
import Formcertified from "./FormRegister/FormCertified";
// Redux
import { RECOVER_STATE } from "../../store/actions/pagesActions";
import { useSelector, useDispatch } from "react-redux";

import {
  SET_HOURS_ERROR,
  SET_MODULES_ERROR,
  SET_TITLE_ERROR,
} from "../../store/actions/validationDataAction";

function ButtonAddcertified({ show, setShow }) {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.initial_states);

  useEffect(() => {
    // aqui eu reseto os estados das pages
    if (!show && Object.keys(initialState).length) {
      dispatch(
        RECOVER_STATE({
          pages: JSON.parse(initialState.pages),
          allSelect: JSON.parse(initialState.allSelect),
        })
      );
      dispatch(SET_TITLE_ERROR(false));
      dispatch(SET_MODULES_ERROR(false));
      dispatch(SET_HOURS_ERROR(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <GrCertificate /> Registro
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Formcertified setShow={setShow} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default ButtonAddcertified;
