import { SET_LOADING_AUTHENTICITY } from "../store/actions/loadingActions";
import { SET_NOTHINGDATA_ERROR } from "../store/actions/validationDataAction";

const validateCertificate = async (
  dispatch,
  certificateNumber,
  setCertificateNotValidadte,
  navigate
) => {
  if (!certificateNumber) {
    dispatch(SET_NOTHINGDATA_ERROR(true));
  } else {
    dispatch(SET_LOADING_AUTHENTICITY(true));
    const http = await fetch("/api/certificate_authenticity", {
      method: "POST",
      headers: new Headers({ "Content-type": "application/json" }),
      body: JSON.stringify({
        certificateId: certificateNumber.toLocaleUpperCase(),
      }),
    });
    if (http.status !== 200) {
      setCertificateNotValidadte(true);
    } else {
      navigate(`/certificado/${certificateNumber.toLocaleUpperCase()}`);
    }
    dispatch(SET_LOADING_AUTHENTICITY(false));
  }
};

export { validateCertificate };
