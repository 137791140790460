import HeaderHome from "../components/HomeSeach/HeaderHome/";

const PublicLayout = ({ children }) => {
  return (
    <div style={{ paddingTop: "80px" }}>
      <HeaderHome />
      {children}
    </div>
  );
};

export default PublicLayout;
