import React from "react";
import { Card, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SELECT_ALL_PAGES } from "../../../store/actions/pagesActions";
import { SET_STATE } from "../../../store/actions/allSelectActions";
import {
  SET_MODULES_ERROR,
  SET_CHANGE_ERROR,
} from "../../../store/actions/validationDataAction";

function SelectAll({ index }) {
  const error_state = useSelector((states) => states.error_state);
  const pages = useSelector((states) => states.pages);
  const states_select_all = useSelector((states) => states.allSelect);
  const dispatch = useDispatch();

  function changeAll() {
    // Caso tenha erro de seleção de aula, aqui ocorre uma remoção do erro
    if (error_state.modulesErr) dispatch(SET_MODULES_ERROR(false));
    if (error_state.changeErr) dispatch(SET_CHANGE_ERROR(false));

    let select_all = states_select_all[`${index}`] === true ? false : true;
    dispatch(SET_STATE({ key: index, value: select_all }));

    const new_state = { ...pages };
    new_state[index] = new_state[index].map((e) => {
      e.isSelect = select_all;
      return e;
    });
    dispatch(SELECT_ALL_PAGES(new_state));
  }

  return (
    <Card>
      <Card.Body className="d-flex align-items-center">
        <InputGroup.Checkbox
          onChange={changeAll}
          checked={states_select_all[`${index}`]}
          aria-label="Checkbox for following text input"
          id={"allSelect" + index}
        />
        <label className="ms-2" htmlFor={"allSelect" + index}>
          Selecionar Tudo
        </label>
      </Card.Body>
    </Card>
  );
}

export default SelectAll;
