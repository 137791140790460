import React, { useEffect, useState } from "react";
// bootstrap
import { Card, Row, Col } from "react-bootstrap";
// actions
import ButtonsActions from "../ButtonsActions";
// redux
import { useSelector } from "react-redux";

// icons
import { AiOutlineBorderlessTable } from "react-icons/ai";
import { GiDiploma } from "react-icons/gi";
// componentes
import ModulesCard from "../ModulesCard";
import InputFilter from "../InputFilter";
import styles from "./main.module.css";

function Cardscertified() {
  const [certifieds, setCertifieds] = useState([]);
  const loading_states = useSelector((state) => state.loading_states);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!loading_states.loading_certificates) {
      fetch("/api/all_certified")
        .then((http) => http.json())
        .then((res) => {
          setCertifieds(() => res);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading_states.loading_certificates]);

  return (
    <>
      <div
        className={`${styles.boxHeader} mb-5 mt-4 d-flex justify-content-between align-items-center`}
      >
        <h1 className="d-flex align-items-center m-0">
          <AiOutlineBorderlessTable />
          Certificados
        </h1>
        <InputFilter search={search} setSearch={setSearch} />
      </div>
      <Row xs={1} md={2} className="g-4 mb-5">
        {certifieds
          .filter((certified) => {
            if (search === "") return certified;
            return certified.title.toLowerCase().includes(search.toLowerCase());
          })
          .map((certified, idx) => (
            <Col
              key={idx}
              className="border-0 shadow-sm rounded"
              style={{ backgroundColor: "white" }}
            >
              <Card className="border-0">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title className="d-flex align-items-center mb-0">
                      <p className="mb-0">
                        <GiDiploma className="me-2 fs-3" />
                        {certified.title}
                      </p>
                    </Card.Title>
                    <ButtonsActions
                      certificate_data={certifieds[idx]}
                      id={certified._id}
                    ></ButtonsActions>
                  </div>
                  <ModulesCard certified={certified} />
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default Cardscertified;
